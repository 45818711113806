@if ((viewModel$ | async); as vm) {
  <lay-panel-block>
    <lay-title>
      <app-text class="is-family-semiBold" [text]="vm.home.name" />
    </lay-title>
  </lay-panel-block>
  <lay-panel-block>
    <lay-link (click)="getNextView({ nextRoute: 'device/', thermostat: vm.thermostat })" [resolve]="false">
      <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right" name="info-vaillant" />
      <app-text class="title" [text]="'common-app.__INFORMATION' | LANG" />
    </lay-link>
  </lay-panel-block>
  <lay-panel-block>
    <lay-link (click)="addDevice()">
      <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
        name="navigation_plus-vaillant" />
      <app-text class="title" [text]="'settings-therm.__ADD_THERM' | LANG" />
    </lay-link>
  </lay-panel-block>
  <lay-panel-block>
    <lay-link (click)="getNextView({ nextRoute: 'heatingsystem/', thermostat: vm.thermostat })" [resolve]="false">
      <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
        name="heating-vaillant" />
      <app-text class="title home-name" [text]="'settings-all.__VAI_SETTINGS_MAIN_HEATING_PARAMS' | LANG" />
    </lay-link>
  </lay-panel-block>
  <lay-panel-block>
    <lay-link (click)="getNextView({ nextRoute: 'users/', thermostat: vm.thermostat})" [resolve]="false">
      <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
        name="group-vaillant" />
      <app-text class="title home-name" [text]="'settings-therm.__USERS' | LANG" />
    </lay-link>
  </lay-panel-block>
}

import { SetHomeConfigPayload } from '@library/store/homes/homes.interface';
import { Action } from '@ngrx/store';
import { AppError } from '../app.types';
import { DHWStorageUpdatePayload } from '../homes/homes.interface';
import { ResetHeatingCurvePayload, SetContactInfoPayload, SetHeatingAlgoPayload, SetHeatingCurvePayload, SetHeatingTypePayload, SetPeriodPayload } from './modules.interface';

export enum EnumModulesActionsVaillant {
    SetHeatingCurve = '[MODULES] Set Heating Curve',
    SetHeatingCurveSuccess = '[MODULES] Set Heating Curve Success',
    SetHeatingCurveFailure = '[MODULES] Set Heating Curve Failure',

    SetHeatingType = '[MODULES] Set heating type',
    SetHeatingTypeSuccess = '[MODULES] Set heating type success',
    SetHeatingTypeFailure = '[MODULES] Set heating type failure',

    ResetHeatingCurve = '[MODULES] Reset Heating Curve',
    ResetHeatingCurveSuccess = '[MODULES] Reset Heating Curve Success',
    ResetHeatingCurveFailure = '[MODULES] Reset Heating Curve Failure',

    ChangeContactService = '[MODULES] Change device contact service',
    ChangeContactServiceSuccess = '[MODULES] Change device contact service success',
    ChangeContactServiceFailure = '[MODULES] Change device contact service failure',

    SetHeatingAlgo = '[MODULES] Set heating algo',
    SetHeatingAlgoSuccess = '[MODULES] Set heating algo success',
    SetHeatingAlgoFailure = '[MODULES] Set heating algo failure',

    SetPeriod = '[MODULES] Set period',
    SetPeriodSuccess = '[MODULES] Set period success',
    SetPeriodFailure = '[MODULES] Set period failure',

    SetDomesticHotWaterTemperature = '[MODULES] Set domestic hot water temperature',
    SetDomesticHotWaterTemperatureSuccess = '[MODULES] Set domestic hot water temperature success',
    SetDomesticHotWaterTemperatureFailure = '[MODULES] Set domestic hot water temperature failure',

    SetDHWControl = '[MODULES] Seet Domestic Hot Water Control Value'
}

/**
 * Set default duration of manual setpoints
 */
export class SetHeatingCurve implements Action {
  public readonly type = EnumModulesActionsVaillant.SetHeatingCurve;
  constructor(public payload: SetHeatingCurvePayload) { }
}
export class SetHeatingCurveSuccess implements Action {
  public readonly type = EnumModulesActionsVaillant.SetHeatingCurveSuccess;
  constructor(public payload: SetHeatingCurvePayload) { }
}
export class SetHeatingCurveFailure implements Action {
  public readonly type = EnumModulesActionsVaillant.SetHeatingCurveFailure;
  constructor(public payload: object, public other: SetHeatingCurvePayload) { }
}

/**
 * Set heating type (radiator, subfloor, unknown)
 */
export class SetHeatingType implements Action {
  public readonly type = EnumModulesActionsVaillant.SetHeatingType;
  constructor(public payload: SetHeatingTypePayload) { }
}
export class SetHeatingTypeSuccess implements Action {
  public readonly type = EnumModulesActionsVaillant.SetHeatingTypeSuccess;
  constructor(public payload: SetHeatingTypePayload) { }
}
export class SetHeatingTypeFailure implements Action {
  public readonly type = EnumModulesActionsVaillant.SetHeatingTypeFailure;
  constructor(public err: AppError) { }
}

/**
 * Reset heating curve to default value
 */
export class ResetHeatingCurve implements Action {
  public readonly type = EnumModulesActionsVaillant.ResetHeatingCurve;
  constructor(public payload: ResetHeatingCurvePayload) { }
}
export class ResetHeatingCurveSuccess implements Action {
  public readonly type = EnumModulesActionsVaillant.ResetHeatingCurveSuccess;
  constructor(public payload: ResetHeatingCurvePayload) { }
}
export class ResetHeatingCurveFailure implements Action {
  public readonly type = EnumModulesActionsVaillant.ResetHeatingCurveFailure;
  constructor(public payload: object, public other: ResetHeatingCurvePayload) { }
}

/**
 * Change contact information
 */
export class ChangeContactService implements Action {
  public readonly type = EnumModulesActionsVaillant.ChangeContactService;
  constructor(public payload: SetContactInfoPayload) { }
}
export class ChangeContactServiceSuccess implements Action {
  public readonly type = EnumModulesActionsVaillant.ChangeContactServiceSuccess;
  constructor(public payload: SetContactInfoPayload) { }
}
export class ChangeContactServiceFailure implements Action {
  public readonly type = EnumModulesActionsVaillant.ChangeContactServiceFailure;
  constructor(public err: object) { }
}

/**
 * Set heating algo
 */
export class SetHeatingAlgo implements Action {
  public readonly type = EnumModulesActionsVaillant.SetHeatingAlgo;
  constructor(public payload: SetHeatingAlgoPayload) { }
}
export class SetHeatingAlgoSuccess implements Action {
  public readonly type = EnumModulesActionsVaillant.SetHeatingAlgoSuccess;
  constructor(public payload: SetHeatingAlgoPayload) { }
}
export class SetHeatingAlgoFailure implements Action {
  public readonly type = EnumModulesActionsVaillant.SetHeatingAlgoFailure;
  constructor(public payload: object, public other: SetHeatingAlgoPayload) { }
}

/**
 * Set algorithm period
 */
export class SetPeriod implements Action {
  public readonly type = EnumModulesActionsVaillant.SetPeriod;
  constructor(public payload: SetPeriodPayload) { }
}
export class SetPeriodSuccess implements Action {
  public readonly type = EnumModulesActionsVaillant.SetPeriodSuccess;
  constructor(public payload: SetPeriodPayload) { }
}
export class SetPeriodFailure implements Action {
  public readonly type = EnumModulesActionsVaillant.SetPeriodFailure;
  constructor(public payload: object) { }
}

/**
 * Set Domestic hot water temperature
 */
export class SetDomesticHotWaterTemperature implements Action {
  public readonly type = EnumModulesActionsVaillant.SetDomesticHotWaterTemperature;
  constructor(public payload: SetHomeConfigPayload) { }
}
export class SetDomesticHotWaterTemperatureSuccess implements Action {
  public readonly type = EnumModulesActionsVaillant.SetDomesticHotWaterTemperatureSuccess;
  constructor(public payload: SetHomeConfigPayload) { }
}
export class SetDomesticHotWaterTemperatureFailure implements Action {
  public readonly type = EnumModulesActionsVaillant.SetDomesticHotWaterTemperatureFailure;
  constructor(public payload: object) { }
}

export class SetDHWControl implements Action {
  public readonly type = EnumModulesActionsVaillant.SetDHWControl;
  constructor(public payload: DHWStorageUpdatePayload) {}
}

export type ModulesActionsVaillant = SetHeatingCurve
    | SetHeatingCurveSuccess
    | SetHeatingCurveFailure
    | SetHeatingType
    | SetHeatingTypeSuccess
    | SetHeatingTypeFailure
    | ResetHeatingCurve
    | ResetHeatingCurveSuccess
    | ResetHeatingCurveFailure
    | ChangeContactService
    | ChangeContactServiceSuccess
    | ChangeContactServiceFailure
    | SetHeatingAlgo
    | SetHeatingAlgoFailure
    | SetHeatingAlgoSuccess
    | SetPeriod
    | SetPeriodFailure
    | SetPeriodSuccess
    | SetDomesticHotWaterTemperature
    | SetDomesticHotWaterTemperatureFailure
    | SetDomesticHotWaterTemperatureSuccess
    | SetDHWControl;

import { Injectable } from '@angular/core';
import { SetHomeConfigPayload } from '@library/store/homes/homes.interface';
import { EnumModulesActions, ModulesActions, UpdateModuleName, UpdateModuleNameFailure, UpdateModuleNameSuccess } from '@library/store/modules/modules.action';
import { ModulesFacade } from '@library/store/modules/modules.facade';
import { ModuleNameUpdate } from '@library/store/modules/modules.interface';
import { SelectRoom } from '@library/store/rooms/rooms.action';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppState } from '../app.state';
import * as AllModulesActionsVaillant from './modules.actions';
import { EnumModulesActionsVaillant, ModulesActionsVaillant } from './modules.actions';
import { ResetHeatingCurvePayload, SetContactInfoPayload, SetHeatingAlgoPayload, SetHeatingCurvePayload, SetHeatingTypePayload, SetPeriodPayload } from './modules.interface';
import * as ModulesSelectorsVaillant from './modules.selectors';

@Injectable({
  providedIn: 'root',
})
export class ModulesFacadeVaillant extends ModulesFacade {
  currentModuleVaillant$ = this.store$.pipe(select(ModulesSelectorsVaillant.getCurrentModuleVaillant));
  getGatewayConfigVaillant$ = this.store$.pipe(select(ModulesSelectorsVaillant.getGatewayConfigVaillant));
  slope$ = this.store$.select(ModulesSelectorsVaillant.getSlope);
  heatingalgo$ = this.store$.select(ModulesSelectorsVaillant.getThermHeatingAlgorithm);
  period$ = this.store$.select(ModulesSelectorsVaillant.getPidPeriod);
  hysteresis$ = this.store$.select(ModulesSelectorsVaillant.getDeadband);
  contactPhone$ = this.store$.pipe(select(ModulesSelectorsVaillant.getContactPhone));
  contactEmail$ = this.store$.pipe(select(ModulesSelectorsVaillant.getContactEmail));
  contactName$ = this.store$.pipe(select(ModulesSelectorsVaillant.getContactName));
  dhwStorage$ = this.store$.pipe(select(ModulesSelectorsVaillant.getDHWStorage));

  /**
   * Error management
   */

  currentThermostatUnreachable$ = this.store$.pipe(select(ModulesSelectorsVaillant.currentThermostatUnreachable));
  maintenanceError$ = this.store$.pipe(select(ModulesSelectorsVaillant.maintenanceError));
  ebusError$ = this.store$.pipe(select(ModulesSelectorsVaillant.ebusError));
  waterPressureLowError$ = this.store$.pipe(select(ModulesSelectorsVaillant.waterPressureLowError));
  internalError$ = this.store$.pipe(select(ModulesSelectorsVaillant.internalError));
  batteryLevel$ = this.store$.pipe(select(ModulesSelectorsVaillant.batteryLevel));
  getMultiErrors$ = this.store$.pipe(select(ModulesSelectorsVaillant.getMultiErrors));

  /**
   * Check for device unreachable error (code 6)
   */
  currentModuleReachabilityErrors$ = this.store$.pipe(
    select(ModulesSelectorsVaillant.currentModuleReachabilityErrors),
  );

  /**
   * Current module errors different than code 6
   */
  currentModuleErrors$ = this.store$.pipe(
    select(ModulesSelectorsVaillant.currentModuleErrors),
  );

  /**
   * Get the gateway of the current selected module
   */
  gatewayOfCurrentModule$ = this.store$.pipe(
    select(ModulesSelectorsVaillant.getGatewayOfCurrentModule),
  );

  /** Vaillant can only have one Gateway per home, so this works */
  currentHomeGateway$ = this.store.pipe(select(ModulesSelectorsVaillant.getCurrentHomeGateway));

  /**
   * Check if the device has been selected and that it exists
   * Used for components guards
   */
  checkExistance$ = this.store$.pipe(
    select(ModulesSelectorsVaillant.checkDeviceExistance),
  );

  constructor(private readonly store: Store<AppState>, private readonly actions: Actions) {
    super(store, actions);
  }

  selectRoom(roomId: string): Observable<boolean> {
    this.store$.dispatch(new SelectRoom(roomId));
    return of(true);
  }

  /**
   * Updates the name of a device
   * @param moduleNameUpdate Parameters of the request: device_id, module_id, home_id, name
   */
  updateModuleName(moduleNameUpdate: ModuleNameUpdate): Observable<UpdateModuleNameSuccess | UpdateModuleNameFailure> {
    this.store$.dispatch(
      new UpdateModuleName(moduleNameUpdate),
    );
    return this.actions$.pipe(
      ofType<ModulesActions>(
        EnumModulesActions.UpdateModuleNameSuccess,
        EnumModulesActions.UpdateModuleNameFailure,
      ),
      take(1),
    ) as Observable<UpdateModuleNameSuccess | UpdateModuleNameFailure>;
  }

  setHeatingCurve(heatingCurveUpdate: SetHeatingCurvePayload): Observable<AllModulesActionsVaillant.ModulesActionsVaillant> {
    this.store$.dispatch(
      new AllModulesActionsVaillant.SetHeatingCurve(heatingCurveUpdate),
    );
    return this.actions$.pipe(
      ofType<ModulesActionsVaillant>(
        EnumModulesActionsVaillant.SetHeatingCurveSuccess,
        EnumModulesActionsVaillant.SetHeatingCurveFailure,
      ),
      take(1),
    );
  }

  setHeatingType(heatingTypeUpdate: SetHeatingTypePayload): Observable<AllModulesActionsVaillant.ModulesActionsVaillant> {
    this.store$.dispatch(
      new AllModulesActionsVaillant.SetHeatingType(heatingTypeUpdate),
    );
    return this.actions$.pipe(
      ofType<ModulesActionsVaillant>(
        EnumModulesActionsVaillant.SetHeatingTypeSuccess,
        EnumModulesActionsVaillant.SetHeatingTypeFailure,
      ),
      take(1),
    );
  }

  setDomesticHotWaterTemperature(dhwUpdate: SetHomeConfigPayload): Observable<AllModulesActionsVaillant.ModulesActionsVaillant> {
    this.store$.dispatch(new AllModulesActionsVaillant.SetDomesticHotWaterTemperature(dhwUpdate));

    return this.actions$.pipe(
      ofType<ModulesActionsVaillant>(
        EnumModulesActionsVaillant.SetDomesticHotWaterTemperatureSuccess,
        EnumModulesActionsVaillant.SetDomesticHotWaterTemperatureFailure,
      ),
      take(1),
    );
  }

  resetHeatingCurve(heatingCurveReset: ResetHeatingCurvePayload): Observable<AllModulesActionsVaillant.ModulesActionsVaillant> {
    this.store$.dispatch(
      new AllModulesActionsVaillant.ResetHeatingCurve(heatingCurveReset),
    );
    return this.actions$.pipe(
      ofType<ModulesActionsVaillant>(
        EnumModulesActionsVaillant.ResetHeatingCurveSuccess,
        EnumModulesActionsVaillant.ResetHeatingCurveFailure,
      ),
      take(1),
    );
  }

  changeContactService(newContact: SetContactInfoPayload): Observable<AllModulesActionsVaillant.ModulesActionsVaillant> {
    if (newContact.device_id) {
      this.store$.dispatch(new AllModulesActionsVaillant.ChangeContactService({ ...newContact, device_id: newContact.device_id }));
    } else {
      this.store$.select(ModulesSelectorsVaillant.getGatewayOfCurrentModule).pipe(
        take(1),
        tap(device => {
          this.store$.dispatch(new AllModulesActionsVaillant.ChangeContactService({ ...newContact, device_id: device.id }));
        }),
      ).subscribe();
    }

    return this.actions$.pipe(
      ofType<ModulesActionsVaillant>(
        EnumModulesActionsVaillant.ChangeContactServiceSuccess,
        EnumModulesActionsVaillant.ChangeContactServiceFailure,
      ),
      take(1),
    );
  }

  setHeatingAlgo(heatingAlgoUpdate: SetHeatingAlgoPayload): Observable<AllModulesActionsVaillant.ModulesActionsVaillant> {
    this.store$.dispatch(
      new AllModulesActionsVaillant.SetHeatingAlgo(heatingAlgoUpdate),
    );
    return this.actions$.pipe(
      ofType<ModulesActionsVaillant>(
        EnumModulesActionsVaillant.SetHeatingAlgoSuccess,
        EnumModulesActionsVaillant.SetHeatingAlgoFailure,
      ),
      take(1),
    );
  }

  setPeriod(periodUpdate: SetPeriodPayload): Observable<AllModulesActionsVaillant.ModulesActionsVaillant> {
    this.store$.dispatch(
      new AllModulesActionsVaillant.SetPeriod(periodUpdate),
    );
    return this.actions$.pipe(
      ofType<ModulesActionsVaillant>(
        EnumModulesActionsVaillant.SetPeriodSuccess,
        EnumModulesActionsVaillant.SetPeriodFailure,
      ),
      take(1),
    );
  }
}

import { Action } from '@ngrx/store';
import { DHWStorageUpdatePayload, HomeVaillant, UpdateHomeHeatingTypePayload } from './homes.interface';

export enum EnumHomesActionsVaillant {
    SetManualSetpointDefaultDuration = '[Homes] Set Manual setpoint default duration',
    SetManualSetpointDefaultDurationSuccess = '[Homes] Set Manual setpoint default duration success',
    SetManualSetpointDefaultDurationFailure = '[Homes] Set Manual setpoint default duration failure',

    SetAnticipationActivation = '[Homes] Set anticipation activation',
    SetAnticipationActivationSuccess = '[Homes] Set anticipation activation Success',
    SetAnticipationActivationFailure = '[Homes] Set anticipation activation Failure',

    SetDHWStorage = '[Homes] Set DHW Storage',
    SetDHWStorageSuccess = '[Homes] Set DHW Storage Success',
    SetDHWStorageFailure = '[Homes] Set DHW Storage Failure',

    UpdateHomeHeatingType = '[Homes Vaillant] Setting Heating Type for Home'
}

/**
 * Set default duration of manual setpoints
 */
export class SetManualSetpointDefaultDuration implements Action {
  public readonly type = EnumHomesActionsVaillant.SetManualSetpointDefaultDuration;
  constructor(public payload: { home: Partial<HomeVaillant> }) { }
}
export class SetManualSetpointDefaultDurationSuccess implements Action {
  public readonly type = EnumHomesActionsVaillant.SetManualSetpointDefaultDurationSuccess;
  constructor(public payload: { home: Partial<HomeVaillant> }) { }
}
export class SetManualSetpointDefaultDurationFailure implements Action {
  public readonly type = EnumHomesActionsVaillant.SetManualSetpointDefaultDurationFailure;
  constructor(public payload: object) { }
}

/**
 * Set anticipation activation true/false
 */
export class SetAnticipationActivation implements Action {
  public readonly type = EnumHomesActionsVaillant.SetAnticipationActivation;
  constructor(public payload: { home: Partial<HomeVaillant> }) { }
}
export class SetAnticipationActivationSuccess implements Action {
  public readonly type = EnumHomesActionsVaillant.SetAnticipationActivationSuccess;
  constructor(public payload: { home: Partial<HomeVaillant> }) { }
}
export class SetAnticipationActivationFailure implements Action {
  public readonly type = EnumHomesActionsVaillant.SetAnticipationActivationFailure;
  constructor(public payload: object) { }
}

/**
 * Set value for DHW Storage
 */
export class SetDHWStorage implements Action {
  public readonly type = EnumHomesActionsVaillant.SetDHWStorage;
  constructor(public payload: DHWStorageUpdatePayload) { }
}
export class SetDHWStorageSuccess implements Action {
  public readonly type = EnumHomesActionsVaillant.SetDHWStorageSuccess;
  constructor(public payload: DHWStorageUpdatePayload) { }
}
export class SetDHWStorageFailure implements Action {
  public readonly type = EnumHomesActionsVaillant.SetDHWStorageFailure;
  constructor(public payload: object) { }
}

export class UpdateHomeHeatingType implements Action {
  public readonly type = EnumHomesActionsVaillant.UpdateHomeHeatingType;
  constructor(public payload: UpdateHomeHeatingTypePayload) {}
}

export type HomesActionsVaillant = SetManualSetpointDefaultDuration
    | SetManualSetpointDefaultDurationFailure
    | SetManualSetpointDefaultDurationSuccess
    | SetAnticipationActivation
    | SetAnticipationActivationSuccess
    | SetAnticipationActivationFailure
    | SetDHWStorage
    | SetDHWStorageSuccess
    | SetDHWStorageFailure
    | UpdateHomeHeatingType;

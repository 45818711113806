import { Injectable } from '@angular/core';
import { RoomsFacade } from '@library/store/rooms/rooms.facade';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getCurrentRoomTempOffset } from './rooms.selector';

@Injectable()
export class VaillantRoomsFacade extends RoomsFacade {
  roomOffset$ = this.store$.select(getCurrentRoomTempOffset);

  constructor(
    protected store$: Store<SettingsState>,
    protected actions$: Actions,
  ) {
    super(store$, actions$);
  }
}

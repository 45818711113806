import { Module } from '@library/store/modules/modules.interface';
import { downloadFile } from '@library/utils/helpers/functions/downloadFile';
import { EnumHomesActions, HomesActions } from '../homes/homes.action';
import { collectionToEntitiesUpdate, getUpdatedCollection } from '../store.functions';
import { EnumRoomsActions, RoomsActions } from './rooms.action';
import { Room } from './rooms.interface';
import { initialRoomsState, RoomsState } from './rooms.state';

const heatingDevices = ['NATherm1', 'NRV', 'BNS', 'OTM', 'BAC', 'NAC', 'NAThermVaillant', 'BNTH', 'NTE']; // !! Don't forget NLC with applicance type radiator
const coolingDevices = ['BNS', 'BAC', 'NAC', 'BNTH', 'BIRE'];
// #TODO Temporary, will need to switch to dynamic capabilites check instead of straight device type check. Will happen once spec is validated
const autoTempDevices = ['NAC', 'BAC', 'BNTH', 'BIRE'];

export function RoomsReducers(state = initialRoomsState, action: RoomsActions | HomesActions): RoomsState {
  switch (action.type) {
    case EnumRoomsActions.GetRoomsSuccess: {
      const allRooms = new Map(state.all.map(room => [room.id, room]));
      // Deep copy of object to prevent memory reference bugs when accessing payload in other reducers
      const data = JSON.parse(JSON.stringify(action.payload));
      data.forEach(homeData => {
        if (homeData.rooms) {
          homeData.rooms.forEach(roomData => {
            const room = roomData as Room;
            // get all modules of room to know if they have heating, cooling or auto devices.
            const roomModules = homeData.modules
              ? homeData.modules.filter(mod => roomData.modules && roomData.modules.includes(mod.id))
              : [];

            room.hasValves = roomModules.some(mod => mod.type === 'NRV');
            room.hasNetatmoThermostat = roomModules.some((mod) => mod.type === 'NATherm1');
            room.hasHeatingDevice = roomModules.some((mod) => heatingDevices.includes(mod.type) ||
              (mod.type === 'NLC' && mod.applicance === 'radiator'));
            room.hasCoolingDevice = roomModules.some((mod) => coolingDevices.includes(mod.type));
            room.hasAutoTempDevice = roomModules.some((mod) => autoTempDevices.includes(mod.type));

            room.homeId = homeData.id;

            allRooms.set(room.id, {
              ...allRooms.get(room.id) ?? {},
              ...room,
            });
          });
        }
      });

      // Add custom rooms for modules without room and/or favorites station
      if (action.modules) {
        const modules: Module[] = JSON.parse(JSON.stringify(action.modules));
        const favoritesModules = modules.filter(module => module.mode === 'favorite');
        const otherModules = modules.filter(module => module.mode !== 'favorite');

        // Add Favorites station as a custom room and add all modules as associated to main modules
        const mainModules = favoritesModules.filter(module => module.type === 'NAMain');
        mainModules.forEach(mainModule => {
          const associatedModulesId = favoritesModules.filter(module => module.bridge === mainModule.id).map(module => module.id);
          allRooms.set(mainModule.id, {
            id: mainModule.id,
            homeId: mainModule.id,
            name: mainModule.name,
            mode: mainModule.mode,
            type: 'custom',
            modules: [...associatedModulesId, mainModule.id],
          } as Room);
        });

        // Add other modules as a custom room
        otherModules.forEach(module => {
          allRooms.set(module.id, {
            id: module.id,
            homeId: module.bridge ?? module.id,
            name: module.name,
            mode: module.mode,
            type: 'custom',
            modules: [module.id],
          } as Room);
        });
      }
      return {
        ...state,
        all: Array.from(allRooms.values()),
      };
    }

    case EnumRoomsActions.GetRoomsHomeCoachsSuccess: {
      const allRooms = new Map(state.all.map(room => [room.id, room]));

      if (action.payload.devices.length > 0) {
        action.payload.devices.forEach(homeCoach => {
          allRooms.set(homeCoach._id, {
            id: homeCoach._id,
            name: homeCoach.station_name,
            type: 'custom',
            mode: 'homeCoach',
            homeId: action.payload.devices[0]._id,
            modules: [homeCoach._id],
          } as Room);
        });
      }

      return {
        ...state,
        all: Array.from(allRooms.values()),
      };
    }

    case EnumRoomsActions.GetRoomsHomeCoachsFailure: {
      return {
        ...state,
      };
    }

    case EnumRoomsActions.GetRoomsFailure: {
      console.error('Failure', action);
      return {
        ...state,
      };
    }
    case EnumRoomsActions.RoomSelected: {
      return {
        ...state,
        currentRoomId: action.payload,
      };
    }
    case EnumRoomsActions.UpdateRoomsStatus: {
      return {
        ...state,
        all: getUpdatedCollection(
          state.all,
          collectionToEntitiesUpdate(action.payload.rooms),
        ),
      };
    }

    case EnumRoomsActions.UpdateRoomsGetConfig: {
      const rooms = action.payload.rooms;
      return {
        ...state,
        all: getUpdatedCollection(
          state.all,
          collectionToEntitiesUpdate(
            rooms ? rooms.map(m => ({ id: m.id, config: m } as Room)) : [],
          ),
        ),
      };
    }

    case EnumRoomsActions.UpdateRoomsSetConfig: {
      const rooms = action.payload.home.rooms;
      return {
        ...state,
        all: getUpdatedCollection(
          state.all,
          collectionToEntitiesUpdate(
            rooms ? rooms.map(r => {
              const oldRoom = state.all.find(room => room.id === r.id);
              const oldConfig = oldRoom ? oldRoom.config : {};
              return { id: r.id, config: { ...oldConfig, ...r } };
            }) : [],
          ),
        ),
      };
    }
    case EnumRoomsActions.SetTrueTemperatureSuccess: {
      const updatedRooms = getUpdatedCollection(state.all, {
        [action.payload.room_id]: {
          therm_measured_temperature: action.payload.corrected_temperature,
        },
      });

      return {
        ...state,
        all: updatedRooms,
      };
    }
    case EnumRoomsActions.SetTrueTemperatureFailure: {
      console.error('Failure', action);
      return {
        ...state,
      };
    }
    case EnumRoomsActions.GetRoomMeasureCsvSuccess: {
      const options = {
        filename: `${action.info.name_room}_${
          (new Date(+ action.info.today * 1000)).toLocaleDateString().replace('/', '_')}.${
          action.info.format}`,
        data: action.payload,
      };
      downloadFile(options);
      return {
        ...state,
      };
    }
    case EnumRoomsActions.GetMeasureCsvSuccess: {
      const options = {
        filename: `${action.info.name_module}_${
          (new Date(+ action.info.today * 1000)).toLocaleDateString().replace('/', '_')}.${
          action.info.format}`,
        data: action.payload,
      };
      downloadFile(options);
      return {
        ...state,
      };
    }
    case EnumRoomsActions.GetMeasureCsvFailure || EnumRoomsActions.GetRoomMeasureCsvFailure: {
      console.error('Failure', action);
      return {
        ...state,
      };
    }

    case EnumRoomsActions.UpdateRoomNameSuccess: {
      const room = state.all.find(r => r.id === action.payload.room_id);

      if (room) {
        room.name = action.payload.name;
      }

      return {
        ...state,
      };
    }

    case EnumRoomsActions.RemoveModuleFromRoom: {
      const all = state.all.map((room) => {
        const roomModules = room.modules || [];
        if (roomModules.includes(action.payload.module_id)) {
          const modules = [...room.modules];
          modules.splice(room.modules.indexOf(action.payload.module_id), 1);
          room.modules = modules;
          room.module_ids = modules;
          // room data given from homesData does not include modules attribute if no modules in room
          // Deleting modules attribute if no modules left in array, to follow design of backend data
          if (room.modules.length === 0) {
            delete room.modules;
            delete room.module_ids;
          }
        }
        return room;
      });

      return {
        ...state,
        all,
      };
    }

    case EnumRoomsActions.AddModuleToRoom: {
      const all = state.all.map((room) => {
        if (room.id === action.payload.room_id) {
          // room data given from homesData does not include modules attribute if no modules in room
          // Need to assign empty array if modules does not exist already on room
          room.modules = room.modules || [];
          room.module_ids = room.modules = room.modules || [];
          room.modules = [...room.modules, action.payload.module_id];
          room.module_ids = [...room.modules, action.payload.module_id];
        }
        return room;
      });

      return {
        ...state,
        all,
      };
    }

    case EnumRoomsActions.CreateNewRoomSuccess: {
      const newRoom: Room = {
        homeId: action.payload.home_id,
        id: action.payload.room_id,
        name: action.payload.name,
        type: action.payload.type,
      };

      const all = [...state.all, newRoom];

      return {
        ...state,
        all,
      };
    }

    case EnumRoomsActions.DeleteRoomSuccess: {
      const all = state.all.filter((room) => {
        return room.id !== action.payload.room_id;
      });

      return {
        ...state,
        all,
      };
    }

    case EnumRoomsActions.UpdateRoomMeasureOffsetSuccess: {
      const all = state.all.map((room) => {
        if (room.id === action.payload[0].room_id) {
          action.payload.forEach((payload) => {
            if (payload.data_type === 'temperature') {
              room.measure_offset_NAPlug_temperature = payload.offset;
            }
            else {
              room.measure_offset_NAPlug_estimated_temperature = payload.offset;
            }
          });
        }
        return { ...room };
      });

      return {
        ...state,
        all,
      };
    }
    case EnumHomesActions.SetThermPointSuccess: {
      const room = state.all.find(room => room.id === action.params.room_id && room.homeId === action.params.home_id);

      const newRoom: Room = {
        ...room,
        therm_setpoint_end_time: action.params.endtime,
        therm_setpoint_mode: action.params.mode,
        therm_setpoint_temperature: action.params.temp,
      };

      return {
        ...state,
        all: [...state.all.filter(h => h.id !== newRoom.id), newRoom],
      };
    }

    default:
      return state;
  }
}

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HOMES_FACADE, HomesFacade } from '../homes/homes.facade';
import { SchedulesFacade } from './schedules.facade';

/**
 * Pre-fetches data before accessing the route
 *
 * How to use it:
 * In the routing module:
 *    - Add "resolve: {schedules: SchedulesResolver}" to the route initial path
 */
@Injectable({ providedIn: 'root' })
export class SchedulesResolver {
  constructor(private readonly facade: SchedulesFacade, @Inject(HOMES_FACADE) private readonly homeFacade: HomesFacade) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Action> | Promise<Action> | Action {
    this.homeFacade.selectHome(route.params.home_id);

    const params: Partial<{
      detailed_config: boolean,
      gateway_types: string
    }> = {};

    if (route.data.gatewayTypes) {
      params.detailed_config = true;
      params.gateway_types = route.data.gatewayTypes;
    }

    return this.facade.loadSchedules(params);
  }
}

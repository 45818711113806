import { Injectable } from '@angular/core';
import { isDefined } from '@library/utils/helpers/store/is-defined';

import { UserFacade } from '@library/store/user/user.facade';
import { UNITS } from '@library/ui/directives/unit/unit.directive';
import { take } from 'rxjs';
import { Const } from '../constants/Const.constant';

@Injectable()
export class UnitConverterService {
  units: {
        system: number;
        wind: number;
        pressure: number;
    };

  constructor(private readonly userFacade: UserFacade) {
    userFacade?.currentUser$.pipe(
      isDefined(),
      take(1)).subscribe(u => {
      this.setUnits(u);
    },
    );
  }

  setUnits(user: {unit_system: number, unit_wind: number, unit_pressure: number}): void {
    this.units = {
      system: user.unit_system,
      wind: user.unit_wind,
      pressure: user.unit_pressure,
    };
  }

  get isUSUnitSystem(): boolean {
    return this.units.system === Const.NAPublicConst.UNIT_US;
  }

  get temperatureUnit(): string {
    return this.isUSUnitSystem ? '°F' : '°C';
  }

  get windUnit(): string {
    switch (this.units.wind) {
      case Const.NAPublicConst.UNIT_WIND_KMH:
        return 'km/h';
      case Const.NAPublicConst.UNIT_WIND_MPH:
        return 'MPH';
      case Const.NAPublicConst.UNIT_WIND_MS:
        return 'm/s';
      case Const.NAPublicConst.UNIT_WIND_BEAUFORT:
      case Const.NAPublicConst.UNIT_WIND_KNOT:
      case Const.NAPublicConst.UNIT_WIND_NUMBER:
      default:
        return '';
    }
  }

  get pressureUnit(): string {
    return UNITS.PRESSURE[this.units.pressure];
  }

  get rainUnit(): string {
    return UNITS.RAIN[this.units.system];
  }

  roundNumber(num, dec): number {
    if (num === null) {
      return null;
    }

    return parseFloat(`${Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec)}`);
  }

  roundSecondToHour(num): number {
    return this.roundNumber(num / 3600, 1);
  }

  /* m/ft <-> m */

  convertFeetToMeters(feet): number {
    return this.roundNumber(feet / 3.281, 0);
  }

  convertMetersToFeet(meters): number {
    return this.roundNumber(meters * 3.281, 0);
  }

  getAltitudeForUser(value): number {
    if (this.isUSUnitSystem) {
      return this.convertMetersToFeet(value);
    }

    return this.roundNumber(value, 0);
  }

  getAltitudeForBackend(value): number {
    if (this.isUSUnitSystem) {
      return this.convertFeetToMeters(value);
    }

    return this.roundNumber(value, 0);
  }

  /* mm <-> in */

  millimetersToInches(value): number {
    return this.roundNumber(value / 25.4, 3);
  }

  inchesToMillimeters(value): number {
    return this.roundNumber(value * 25.4, 2);
  }

  getRainForUser(value): number {
    if (this.isUSUnitSystem) {
      return this.millimetersToInches(value);
    }

    return this.roundNumber(value, 1);
  }

  getRainForBackend(value): number {
    if (this.isUSUnitSystem) {
      return this.inchesToMillimeters(value);
    }

    return this.roundNumber(value, 2);
  }

  /* C° <-> F° */

  celciusToFahr(celcius, round = true): number {
    if ('undefined' === typeof round) {
      round = true;
    }

    const fahr = celcius * 9 / 5 + 32;

    return round ? this.roundNumber(fahr, 1) : fahr;
  }

  fahrToCelcius(fahr, round = true): number {
    if ('undefined' === typeof round) {
      round = true;
    }

    const celcius = (fahr - 32) * 5 / 9;

    return round ? this.roundNumber(celcius, 1) : celcius;
  }

  getTemperatureForUser(value): number {
    if (this.isUSUnitSystem) {
      return this.celciusToFahr(value);
    }

    return this.roundNumber(value, 1);
  }

  getTemperatureForBackend(value): number {
    if (this.isUSUnitSystem) {
      return this.fahrToCelcius(value);
    }

    return this.roundNumber(value, 1);
  }

  /* ml <-> Oz */

  mLToOnces(value): number {
    return value / 29.573;
  }

  oncesToML(value): number {
    return value * 29.573;
  }

  getVolumeForUser(value): number {
    if (this.isUSUnitSystem) {
      return this.mLToOnces(value);
    }

    return value;
  }

  getVolumeForBackend(value): number {
    if (this.isUSUnitSystem) {
      return this.oncesToML(value);
    }

    return value;
  }

  /* mbar <-> inHg */

  millibarsToInchesMercury(mb): number {
    return this.roundNumber(mb * 0.02953, 2);
  }

  inchesMercuryToMillibars(inch): number {
    return this.roundNumber(inch / 0.02953, 2);
  }

  /* mbar <-> Torr */

  millibarsToTorr(mb): number {
    return this.roundNumber(mb * 0.75006375541921, 1);
  }

  torrToMillibars(torr): number {
    return this.roundNumber(torr / 0.75006375541921, 2);
  }

  getPressureForUser(value): number {
    switch (this.units.pressure) {
      case Const.NAPublicConst.UNIT_PRESSURE_MERCURY:
        return this.millibarsToInchesMercury(value);

      case Const.NAPublicConst.UNIT_PRESSURE_TORR:
        return this.millibarsToTorr(value);

      case Const.NAPublicConst.UNIT_PRESSURE_MBAR:
      default:
        return this.roundNumber(value, 1);
    }
  }

  getPressureForBackend(value): number {
    switch (this.units.pressure) {
      case Const.NAPublicConst.UNIT_PRESSURE_MERCURY:
        return this.inchesMercuryToMillibars(value);

      case Const.NAPublicConst.UNIT_PRESSURE_TORR:
        return this.torrToMillibars(value);

      case Const.NAPublicConst.UNIT_PRESSURE_MBAR:
      default:
        return this.roundNumber(value, 1);
    }
  }

  /* km/h <-> m/s */

  kilometerPerHourToMeterPerSecond(value): number {
    return this.roundNumber(value / 3.6, 1);
  }

  meterPerSecondToKilometerPerHour(value): number {
    return this.roundNumber(value * 3.6, 1);
  }

  /* km/h <-> mi/h */

  kilometerPerHourToMilesPerHour(value): number {
    return this.roundNumber(value / 1.609344, 0);
  }

  milesPerHourToKilometerPerHour(value): number {
    return this.roundNumber(value * 1.609344, 0);
  }

  /* km/h <-> kt */

  kilometerPerHourToKnot(value, round = true): number {
    if ('undefined' === typeof round) {
      round = true;
    }

    if (round) {
      return this.roundNumber(value * 0.5400, 1);
    }

    return value * 0.5400;
  }

  knotToKilometerPerHour(value, round = true): number {
    if ('undefined' === typeof round) {
      round = true;
    }

    if (round) {
      return this.roundNumber(value / 0.5400, 1);
    }

    return value / 0.5400;
  }

  /* km/h <-> beaufort */

  kilometerPerHourToBeaufort(value): number {
    if (value < 1) {
      return 0;
    }
    else if (value < 6) {
      return 1;
    }
    else if (value < 12) {
      return 2;
    }
    else if (value < 20) {
      return 3;
    }
    else if (value < 29) {
      return 4;
    }
    else if (value < 39) {
      return 5;
    }
    else if (value < 50) {
      return 6;
    }
    else if (value < 62) {
      return 7;
    }
    else if (value < 75) {
      return 8;
    }
    else if (value < 89) {
      return 9;
    }
    else if (value < 103) {
      return 10;
    }
    else if (value < 118) {
      return 11;
    }
    return 12;
  }

  beaufortToKilometerPerHour(value): number {
    if (value === 0) {
      return 0;
    }
    else if (value === 1) {
      return 1;
    }
    else if (value === 2) {
      return 6;
    }
    else if (value === 3) {
      return 12;
    }
    else if (value === 4) {
      return 20;
    }
    else if (value === 5) {
      return 29;
    }
    else if (value === 6) {
      return 39;
    }
    else if (value === 7) {
      return 50;
    }
    else if (value === 8) {
      return 62;
    }
    else if (value === 9) {
      return 75;
    }
    else if (value === 10) {
      return 89;
    }
    else if (value === 11) {
      return 103;
    }

    return 1;
  }

  getWindForUser(value): number {
    switch (this.units.wind) {
      case Const.NAPublicConst.UNIT_WIND_MPH:
        return this.kilometerPerHourToMilesPerHour(value);

      case Const.NAPublicConst.UNIT_WIND_MS:
        return this.kilometerPerHourToMeterPerSecond(value);

      case Const.NAPublicConst.UNIT_WIND_BEAUFORT:
        return this.kilometerPerHourToBeaufort(value);

      case Const.NAPublicConst.UNIT_WIND_KNOT:
        return this.kilometerPerHourToKnot(value);

      default: // Const.NAPublicConst.UNIT_WIND_KMH
        return this.roundNumber(value, 0);
    }
  }

  getWindForBackend(value): number {
    switch (this.units.wind) {
      case Const.NAPublicConst.UNIT_WIND_MPH:
        return this.milesPerHourToKilometerPerHour(value);

      case Const.NAPublicConst.UNIT_WIND_MS:
        return this.meterPerSecondToKilometerPerHour(value);

      case Const.NAPublicConst.UNIT_WIND_BEAUFORT:
        return this.beaufortToKilometerPerHour(value);

      case Const.NAPublicConst.UNIT_WIND_KNOT:
        return this.knotToKilometerPerHour(value);

      default: // Const.NAPublicConst.UNIT_WIND_KMH
        return value;
    }
  }

  convertFromUserUnit(value, dataType: string, userUnit: number | string, userWindUnit, userPressureUnit): number {
    dataType = dataType.toLowerCase();
    userUnit = typeof userUnit === 'string' ? parseInt(userUnit, 10) : userUnit;
    userWindUnit = parseInt(userWindUnit, 10);

    if (typeof(userPressureUnit) === 'undefined') {
      switch (userUnit) {
        case Const.NAPublicConst.UNIT_US:
          userPressureUnit = Const.NAPublicConst.UNIT_PRESSURE_MERCURY;
          break;

        case Const.NAPublicConst.UNIT_METRIC:
        default:
          userPressureUnit = Const.NAPublicConst.UNIT_PRESSURE_MBAR;
          break;
      }
    }
    else {
      userPressureUnit = parseInt(userPressureUnit, 10);
    }

    switch (dataType) {
      case 'temperature':
        return this.getTemperatureForBackend(value);

      case 'pressure':
        return this.getPressureForBackend(value);

      case 'altitude':
        return this.getAltitudeForBackend(value);

      case 'rain':
        return this.getRainForBackend(value);

      case 'liquide':
        return this.getVolumeForBackend(value);

      case 'wind':
        return this.getWindForBackend(value);

      case 'co2':
      case 'particle':
      case 'humidity':
      case 'noise':
      default:
        return Math.round(value);
    }
  }

  convertToUserUnit(value, dataType, userUnit, userWindUnit, userPressureUnit): number {
    dataType = dataType.toLowerCase();
    userUnit = parseInt(userUnit, 10);
    userWindUnit = parseInt(userWindUnit, 10);

    if (typeof(userPressureUnit) === 'undefined') {
      switch (userUnit) {
        case Const.NAPublicConst.UNIT_US:
          userPressureUnit = Const.NAPublicConst.UNIT_PRESSURE_MERCURY;
          break;

        case Const.NAPublicConst.UNIT_METRIC:
        default:
          userPressureUnit = Const.NAPublicConst.UNIT_PRESSURE_MBAR;
          break;
      }
    }
    else {
      userPressureUnit = parseInt(userPressureUnit, 10);
    }

    switch (dataType) {
      case 'temperature':
        return this.getTemperatureForUser(value);

      case 'pressure':
        return this.getPressureForUser(value);

      case 'altitude':
        return this.getAltitudeForUser(value);

      case 'rain':
        return this.getRainForUser(value);

      case 'liquide':
        return this.getVolumeForUser(value);

      case 'wind':
        return this.getWindForUser(value);

      case 'co2':
      case 'particle':
      case 'humidity':
      case 'noise':
      default:
        return Math.round(value);
    }
  }
}

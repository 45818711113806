import { Injectable } from '@angular/core';
import { CreateNewSchedule } from '@library/store/schedules/schedules.action';
import { SchedulesService } from '@library/store/schedules/schedules.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as SchedulesActionVaillant from './schedules.action';
import { SchedulesFacadeVaillant } from './schedules.facade';
import { EventSchedule } from './schedules.interface';

@Injectable()
export class SchedulesEffectsVaillant {
  constructor(
    private readonly schedulesService: SchedulesService,
    private readonly actions$: Actions,
    private readonly schedulesFacade: SchedulesFacadeVaillant,
  ) { }

  createNewScheduleVaillant$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SchedulesActionVaillant.CreateNewScheduleVaillant>
      (SchedulesActionVaillant.EnumSchedulesActionsVaillant.CreateNewScheduleVaillant),
      switchMap((action) => {
        return this.schedulesService.createNewSchedule(action.payload).pipe(
          map((response) => response),
          switchMap((response) => {
            /* Create the corresponding event schedule and link it to the 'therm' schedule using response.body.schedule_id
             that we put in the 'schedule_hwb_associated_vaillant' property of the event schedule */
            let scheduleAction: EventSchedule;
            this.schedulesFacade.createEventZones(action.payload.timetable, action.payload.name, response.body.schedule_id).subscribe(
              (val: EventSchedule) => scheduleAction = val);
            return [
              new SchedulesActionVaillant.CreateNewScheduleVaillantSuccess({
                schedule_id: response.body.schedule_id, schedule_info: action.payload,
              }),
              new CreateNewSchedule({ ...scheduleAction }),
            ];
          }),
          catchError(({ error }) => of(new SchedulesActionVaillant.CreateNewScheduleVaillantFailure(error))),
        );
      }),
    ),
  );
}

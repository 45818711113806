import { Injectable } from '@angular/core';
import { HomesService } from '@library/store/homes/homes.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { EnumModulesActionsVaillant, SetDHWControl, SetHeatingTypeSuccess } from '../modules/modules.actions';
import { ModulesFacadeVaillant } from '../modules/modules.facade';
import {
  EnumHomesActionsVaillant,
  SetAnticipationActivation,
  SetAnticipationActivationFailure,
  SetAnticipationActivationSuccess,
  SetDHWStorage,
  SetDHWStorageFailure,
  SetDHWStorageSuccess,
  SetManualSetpointDefaultDuration,
  SetManualSetpointDefaultDurationFailure,
  SetManualSetpointDefaultDurationSuccess,
  UpdateHomeHeatingType,
} from './homes.actions';

@Injectable()
export class HomesEffectsVaillant {
  constructor(
    private readonly homesService: HomesService,
    private readonly moduleFacade: ModulesFacadeVaillant,
    private readonly actions$: Actions,
  ) { }

  setManualSetpointDefaultDuration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetManualSetpointDefaultDuration>(EnumHomesActionsVaillant.SetManualSetpointDefaultDuration),
      switchMap((action) => {
        return this.homesService.setHomeData(action.payload).pipe(
          switchMap(() => {
            return [new SetManualSetpointDefaultDurationSuccess(action.payload)];
          },
          ),
          catchError(({ error }) =>
            of(new SetManualSetpointDefaultDurationFailure(error)),
          ),
        );
      }),
    ),
  );

  setAnticipationActivation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetAnticipationActivation>(EnumHomesActionsVaillant.SetAnticipationActivation),
      switchMap((action) => {
        return this.homesService.setHomeData(action.payload).pipe(
          switchMap(() => {
            return [new SetAnticipationActivationSuccess(action.payload)];
          },
          ),
          catchError(({ error }) =>
            of(new SetAnticipationActivationFailure(error)),
          ),
        );
      }),
    ),
  );

  setDHWStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetDHWStorage>(EnumHomesActionsVaillant.SetDHWStorage),
      switchMap((action) => {
        return this.homesService.setHomeData(action.payload).pipe(
          switchMap(() => {
            return [
              new SetDHWStorageSuccess(action.payload),
              new SetDHWControl(action.payload),
            ];
          },
          ),
          catchError(({ error }) =>
            of(new SetDHWStorageFailure(error)),
          ),
        );
      }),
    ),
  );

  updateHeatingType$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetHeatingTypeSuccess>(EnumModulesActionsVaillant.SetHeatingTypeSuccess),
      withLatestFrom(this.moduleFacade.gatewayOfCurrentModule$),
      switchMap(([action, gateway]) => {
        return [
          new UpdateHomeHeatingType({ ...action.payload, homeId: gateway.homeId }),
        ];
      }),
    ),
  );
}

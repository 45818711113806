import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ModuleVaillant } from './modules.interface';

const emptyModules: ModuleVaillant[] = [];

/**
 * Used to update one property at a time when we have multiple optional properties in the payload
 */
export const modulesAdapter: EntityAdapter<ModuleVaillant> = createEntityAdapter<ModuleVaillant>();

export interface ModulesStateVaillant extends EntityState<ModuleVaillant> {
  all: ModuleVaillant[];
  currentModuleId: string;
  loading: boolean;
}

// export const modulesAdapter: EntityAdapter<ModuleVaillant> = createEntityAdapter<ModuleVaillant>();

export const initialModulesStateVaillant: ModulesStateVaillant = modulesAdapter.getInitialState({
  all: emptyModules,
  currentModuleId: null,
  loading: false,
});

import { Capability, SyncError } from '../homes/homes.interface';

export enum ElectricityPhase {
  NONE = 'none',
  ONE = '1',
  TWO = '2',
  THREE = '3',
}

export enum SourceType {
  SOCKETS = 'sockets',
  LIGHTS = 'lights',
  HEATERS = 'heaters',
  COOKTOP = 'cooktop',
  OVEN = 'oven',
  ROLLER_SHUTTERS = 'roller_shutters',
  SANITARY_HOT_WATER = 'sanitary_hot_water',
  AIR_CONDITIONER = 'air_conditioner',
  COLD_WATER = 'cold_water',
  HOT_WATER = 'hot_water',
  GAS = 'gas',
  SOLAR_PANEL = 'solar_panel',
  OTHER = 'other',
}

export enum ConsumptionType {
  ELECTRICAL = 'electrical',
  FLUID = 'fluid',
}

export enum ApplianceType {
  COOKING = 'cooking',
  RADIATOR = 'radiator',
  RADIATOR_WITHOUT_PILOT_WIRE = 'radiator_without_pilot_wire',
  WATER_HEATER = 'water_heater',
  ELECTRIC_CHARGER = 'electric_charger',
  LIGHT = 'light',
  FRIDGE_FREEZER = 'fridge_freezer',
  WASHING_MACHINE = 'washing_machine',
  TUMBLE_DRYER = 'tumble_dryer',
  DISHWASHER = 'dishwasher',
  MULTIMEDIA = 'multimedia',
  ROUTER = 'router',
  OTHER = 'other',
  OVEN = 'oven',
  SHUTTERS = 'shutters',
  CURTAINS = 'curtains',
  SHADE = 'shade',
  AIR_CONDITIONER = 'air_conditioner',
  GARAGE_DOOR = 'garage_door',
  GATE = 'gate',
  BSO = 'orientable_sun_shade',
  FAN = 'fan',
}

export interface ModuleConfig {
  id?: string;
  enable_dimmer?: boolean;
  enable_scheduled_mode?: string;
  forgotten_light?: boolean;
  touch_enabled?: boolean;
  favorite_colors?: Color[];
  haptic_enabled?: boolean;
  beep_enabled?: boolean;
  dhw_temperature?: number;
  dhw_setpoint_temperature?: number;
  screen_brightness?: number;
  specifications?: CapabilitiesSpecifications;
  fcu_minimum_fan_speed?: number;
  fcu_post_heating_ventilation_time?: number;
  fcu_post_cooling_ventilation_time?: number;
}

export interface CapabilitiesSpecifications {
  temperature_control_mode: {
    type: string;
    values: string[]; // ["heating", "cooling", "auto"]
},
  therm_setpoint_temperature: {
      type: string;
      min: number;
      max: number;
      step: number;
  },
  therm_fan_mode: {
      type: string;
      values: string[]; // manual or auto
  },
  therm_fan_speed: {
      type: string;
      min: number;
      max: number;
      step: number;
  },
  cooling_setpoint_temperature: {
      type: string;
      min: number;
      max: number;
      step: number;
  },
  cooling_fan_mode: {
      type: string;
      values: string[]; // manual or auto
  },
  cooling_fan_speed: {
      type: string;
      min: number;
      max: number;
      step: number
  },
  auto_setpoint_temperature: {
      type: string;
      min: number;
      max: number;
      step: number;
  },
  auto_fan_mode: {
      type: string;
      values: string[] // manual or auto
  },
  auto_fan_speed: {
      type: string;
      min: number;
      max: number;
      step: number;
  },
  humidity: {
    type: string;
    min: number;
    max: number;
    step: number;
  }
  alerts: {
    type: string;
    values: string[]
  }

}

export interface Color {
  brightness: number;
  hue?: number;
  id: string;
  saturation: number;
  color_temperature?: number
}

export interface Module {
  control?;
  status_loaded?: boolean;
  id: string;
  name?: string;
  type?: string;
  bridge?: string;
  room_id?: string;
  hasDimmerConfig?: boolean;
  hasLocatorConfig?: boolean;
  hasReflectOutletConfig?: boolean;
  hasPowerConfig?: boolean;
  hasAlerts?: boolean;
  canImportData?: boolean;
  reachable?: boolean;
  config?: ModuleConfig;
  error?: ModuleError;
  canControlTemperature?: boolean;
  firmware_revision?: number;
  linked_station_mac?: string;
  appliance_type?: ApplianceType;
  homeId: string;
  therm_heating_algorithm?: string;
  pid_algo_period?: number;
  simple_heating_algo_deadband?: number;
  wifi_status?: number;
  wifi_strength?: number;
  wifi_state?: string;
  rf_status?: number;
  rf_state?: string;
  rf_strength?: number;
  battery_vp?: number;
  hardware_version?: number;
  station_version?: string;
  last_status_store?: number;
  last_seen?: number;
  behavior?: number;
  modules_bridged?: string[];
  public_ext_data?: boolean;
  electricity_phase?: ElectricityPhase;
  source_type?: SourceType;
  consumption_type?: ConsumptionType;
  measured_elec_type?: 'consumption' | 'production';
  customizable?: boolean;
  capabilities?: Capability[];
  offload_priority?: number;
  is_local?: boolean;
  vpn_url?: string;
  sd_status?: number;
  alim_status?: number;
  monitoring?: 'on' | 'off';
  status?: string;
  /** Used by Netatmo Siren */
  low_battery?: boolean;
  /** Used by Netatmo Smoke Detector */
  battery_alarm_state?: string;
  battery_state?: 'unknown' | 'very_low' | 'low' | 'medium' | 'high' | 'full';
  detection_chamber_status?: string;
  status_active?: boolean;
  hush_mode?: boolean;
  smoke_detected?: boolean;
  status_tampered?: boolean;
  status_low_battery?: boolean;
  sound_test?: string;
  /** Used by Netatmo NCO */
  status_fault?: boolean;
  co_alarm_status?: string;
  co_pre_alarm_status?: string;
  /** OTHER */
  category?: string;
  roomName?: string;
  power?: number;
  apparent_power?: number;
  measures_scope?: 'device' | 'total';
  channel?: number; // NLE channel

  // Only for friends & favorites
  coordinates?: number[];
  city?: string;
  altitude?: number;
  sum_rain_24?: number,
  sum_rain_1?: number,
  rain?: number,
  wind_strength?: number,
  wind_angle?: number,
  wind_gust?: number,
  wind_gust_angle?: number,

  // colors
  color_mode?: 'temperature' | 'color';
  hue?: number;
  saturation?: number;
  brightness?: number;
  color_temperature?: number;
  'color_temperature:max'?: number;
  'color_temperature:min'?: number;
  on?: boolean;

  // controllers
  'target_position:step'?: number;
  target_position?: number;
  target_orientation?: number;

  read_only?: boolean;

  Temperature?: number;
  CO2?: number;
  Humidity?: number;
  Noise?: number;
  Pressure?: number;

  e2e_security_key_present?: boolean;
  boiler_status?: boolean;
  mode?: 'friend' | 'favorite';
  home_name?: string;

  last_energy_bilan_year?: number;
  last_energy_bilan_month?: number;
  subtype?: string;

  recording_video_ids?: string[];

  // properties used in muller
  electrical_subscription?: string
  muller_type?: string
}

export interface ModuleData {
  id: string;
  name?: string;
  type?: string;
  bridge?: string;
  room_id?: string;
  hasDimmerConfig?: boolean;
  hasLocatorConfig?: boolean;
  hasReflectOutletConfig?: boolean;
  hasPowerConfig?: boolean;
  hasAlerts?: boolean;
  canImportData?: boolean;
  reachable?: boolean;
  config?;
  error?: ModuleError;
  firmware_revision?: number;
  linked_station_mac?: string;
  appliance_type?: string;
  mode?: 'friend' | 'favorite';
}

export interface ModuleError {
  code: number;
}

export interface HomeErrors {
  id: string;
  errors: SyncError[];
}

export interface ModuleNameUpdate {
  module_id: string;
  name: string;
  home_id: string;
  device_id: string;
}

export interface ModuleRoomUpdate {
  home_id: string;
  device_id: string;
  module_id?: string;
  room_id: string;
}

export interface RemoveModulePayload {
  home_id: string;
  device_id: string;
  room_id: string;
}
export interface Track {
  id : string;
  title : string;
  type : number;
  album : string;
  creator : string;
}

export interface ChangePolarityPayload {
  device_id: string;
  relay_inv_en: string;
}

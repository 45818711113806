import { NgModule } from '@angular/core';

/**
 * Abstract
 * Module for Abstracts necessary for build process, nothing else
 */
import { AbstractModalComponent } from './abstract/modal/abstractmodal.component';

/**
 * Fundamental
 */
import { FunTextModule } from './fundamental/text/text.module'; // to remove one day

import { AtomsModule } from './atoms/atoms.module';
import { LayoutModule } from './layout/layout.module';
import { MoleculesModule } from './molecules/molecules.module';

@NgModule({
  imports: [
    FunTextModule, // remove ?
    AtomsModule,
    LayoutModule,
    MoleculesModule,
  ],
  exports: [
    FunTextModule, // remove ?
    AtomsModule,
    LayoutModule,
    MoleculesModule,
  ],
  declarations: [AbstractModalComponent],
})
export class UIModule {}

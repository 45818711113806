import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, input, Input, ViewEncapsulation } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lay-panel-block',
  templateUrl: './panel-block.component.html',
  styleUrls: ['./panel-block.component.sass'],
  encapsulation: ViewEncapsulation.None,
})

export class PanelBlockComponent {
  @Input() styleClass = '';
  @Input() size: 'small' | 'default' | 'large' | 'fluid' = 'default';
  @Input() isBottomFixed;
  transparent = input(false, { transform: booleanAttribute });

  @HostBinding('class') get sizeClass():string {
    return `panel-block is-${this.size} ${this.styleClass} ${this.transparent ? 'is-transparent-background is-borderless' : ''}`;
  }
}

/**
 * Used in Settings Legrand, Settings Lux
 */

import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigFacade } from '@library/store/config/config.facade';
import { ConfigInterface } from '@library/store/config/config.interface';
import { EnumHomesActions } from '@library/store/homes/homes.action';
import { Loader } from '@library/utils/classes/loader';
import { LangPipe } from '@library/utils/pipes/lang.pipe';
import { UserAgentService } from '@library/utils/services/user-agent.service';
import { HomesFacadeVaillant } from 'app/stores/homes/homes.facade';
import { ModulesFacadeVaillant } from 'app/stores/modules/modules.facade';
import { PageLoaderFacade } from 'app/stores/page-loader/page-loader.facade';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { IMPRINT_URLS, MYACCOUNT_URLS } from 'src/assets/constant/app.constant';
import { ViewComponent } from '../view.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-entry-page-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.scss'],
})
export class HomeViewComponent extends ViewComponent implements OnInit {
  /**
   * These are the 10 numbers that identify Demir Dokum products on migo
   * Ex: 21 XXXX 0020197228 XXXX XXXXXX N5
   */
  migoSerial = '0020197228';

  private readonly url = window.location.href;

  data$ = combineLatest([
    this.configFacade.config$,
    this.homesFacadeVaillant.currentHomeId$,
    this.modulesFacadeVaillant.gatewayOfCurrentModule$,
  ]).pipe(
    map(([config, homeId, gateway]) => ({ config, homeId, gateway })),
  );

  /**
   * Loader handling the spinning of the thermostat button
   */
  confirmLoader = new Loader(this.actions);

  constructor(
    injector: Injector,
    public configFacade: ConfigFacade,
    private readonly langPipe: LangPipe,
    private readonly cookieService: CookieService,
    private readonly pageLoaderFacade: PageLoaderFacade,
    protected router: Router,
    private readonly homesFacadeVaillant: HomesFacadeVaillant,
    private readonly modulesFacadeVaillant: ModulesFacadeVaillant,
    private readonly userAgent: UserAgentService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.header.push({
      title: this.langPipe.transform('common-settings.__MY_SETTINGS'),
    });
    this.pageLoaderFacade.handlePageLoader();
  }

  getFeedback(homeId: string):void {
    // Load home status for heating system view
    this.homesFacadeVaillant.loadHomeStatus({ home_id: homeId }).pipe(
      first(),
    ).subscribe(() => {
      // Listen for actions to wait for GetHomes result
      this.confirmLoader.loadUntil([EnumHomesActions.GetHomeStatusSuccess, EnumHomesActions.GetHomeStatusFailure]);
      this.router.navigate(['feedback/'], {
        relativeTo: this.activatedRoute,
      });
    });
  }

  getContactService(homeId: string):void {
    // Load home status for heating system view
    this.homesFacadeVaillant.loadHomes({ home_id: homeId, detailed_config: true }).pipe(
      first(),
    ).subscribe(() => {
      // Listen for actions to wait for GetHomes result
      this.confirmLoader.loadUntil([EnumHomesActions.GetHomesSuccess, EnumHomesActions.GetHomesFailure]);
      this.router.navigate(['/contact/', homeId], {
        relativeTo: this.activatedRoute,
      });
    });
  }

  /**
   * getMyAccountUrl and getImprint have the same logic, they load the home status to get the oem_serial of the current gateway
   * That is needed to check if the device is from the manufacturer Demir Dokum that has a different color theme and legals page
   * getMyAccountUrl: Load the account of the current user on https://myconnectedaccount.netatmo.com/
   * @param config The app config from the "settings...config.json" needed to get the app name
   * @param gateway The current gateway that will contain the oem_serial to check if the device is from Demir Dokum
   * @param home Current home needed to load the home status
   */
  getMyAccountUrl(config: ConfigInterface):void {
    if (this.url.includes('com.netatmo.vaillant')) {
      window.location.href = config.myAccountUrl + MYACCOUNT_URLS['vaillant'];
    }
    else if (this.url.includes('com.netatmo.migo.demirdokum')) {
      window.location.href = config.myAccountUrl + MYACCOUNT_URLS['demirdokum'];
    }
    else if (this.url.includes('com.netatmo.migo')) {
      window.location.href = config.myAccountUrl + MYACCOUNT_URLS['migo'];
    }
  }

  /**
   * Load the legals page based on the user and his device's type
   * Demir Dokum are only on migo and can be found using the oem serial
   */
  getImprint():void {
    let url: string;
    if (this.url.includes('com.netatmo.vaillant')) {
      url = IMPRINT_URLS['vaillant'];
    }
    else if (this.url.includes('com.netatmo.migo.demirdokum')) {
      url = IMPRINT_URLS['demirdokum'];
    }
    else if (this.url.includes('com.netatmo.migo')) {
      url = IMPRINT_URLS['migo'];
    }

    if (this.userAgent.isNativeApp()) {
      this.callback.call(this.callback.list.openInBrowser, { url, exit: false });
    }
    else {
      window.open(url, '_blank');
    }
  }

  logout(config: ConfigInterface):void {
    if (this.userService.isNativeApp()) {
      this.callback.call(this.callback.list.logout);
    } else {
      this.cookieService.delete(`${config.cookiePrefix}access_token`, '/', config.domain);
      top.location.href = `${config.authUrl
      }?message=__DISCONNECTED`
        + `&next_url=${encodeURIComponent(config.appUrl)}`;
    }
  }
}

import { getCurrentHomeId, getHomes } from '@library/store/homes/homes.selector';
import { createSelector } from '@ngrx/store';
import { HomeVaillant } from './homes.interface';

export const getCurrentHomeVaillant = createSelector(
  getHomes,
  getCurrentHomeId,
  (homes, id) => {
    return homes.find(home => home.id === id) as HomeVaillant;
  },
);

export const getSetpoinDefaultDuration = createSelector(
  getCurrentHomeVaillant,
  (home) => home?.therm_setpoint_default_duration,
);

export const getAnticipation = createSelector(
  getCurrentHomeVaillant,
  (home) => !!home?.anticipation,
);

export const getHeatingType = createSelector(
  getCurrentHomeVaillant,
  (home) => (home?.therm_heating_type ? home.therm_heating_type : 'unknown'),
);

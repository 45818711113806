import { Injectable } from '@angular/core';
import { EnumLangActions, LoadLangsSuccess } from '@library/store/lang/lang.actions';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { InitFacade } from './init.facade';

@Injectable()
export class InitLoader {
  langByUser: object;
  constructor(private readonly actions$: Actions, private readonly initFacade: InitFacade) {}

  load(): Promise<LoadLangsSuccess> {
    this.initFacade.start();
    return this.actions$
      .pipe(
        ofType<LoadLangsSuccess>(EnumLangActions.LoadLangsSuccess),
        take(1),
      ).toPromise(); // Without .toPromise, the loader is not working
  }
}

export function InitLoaderFactory(service: InitLoader) {
  return () => service.load();
}

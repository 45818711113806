import { Injectable } from '@angular/core';
import { NgrxHttpClient } from '@library/utils/services/http-service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../app.state';
import { ResetHeatingCurvePayload, SetContactInfoPayload, SetHeatingAlgoPayload, SetHeatingCurvePayload, SetHeatingTypePayload } from './modules.interface';

@Injectable({
  providedIn: 'root',
})
export class ModulesServiceVaillant {
  constructor(
    private readonly http: NgrxHttpClient,
    private readonly store: Store<AppState>,
  ) { }

  setHeatingCurve(params: SetHeatingCurvePayload | ResetHeatingCurvePayload): Observable<unknown> {
    return this.http.post('changeheatingcurve', params);
  }

  setHeatingType(payload: SetHeatingTypePayload): Observable<unknown> {
    return this.http.post('setheatingsystem', { ...payload });
  }

  changeContactService(data: SetContactInfoPayload): Observable<unknown> {
    if (data.reset) {
      return this.http.post('changecontactservice', { device_id: data.device_id, reset: true });
    }
    return this.http.post('changecontactservice', data);
  }

  setHeatingAlgo(data: SetHeatingAlgoPayload): Observable<unknown> {
    return this.http.post('changeheatingalgo', data);
  }

  setPeriod(params): Observable<unknown> {
    return this.http.post('changepidperiod', params);
  }
}

<lay-main>
  <lay-header role="header" />
</lay-main>

@if (isPageLoading) {
  <div class="page-overlay">
    <span class="page-loader"></span>
  </div>
}

<global-error-popup />

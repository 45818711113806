import { Directive, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigFacade } from '@library/store/config/config.facade';
import { CallbackService } from '@library/utils/services/callback.service';
import { HeaderService } from '@library/utils/services/header.service';
import { UserAgentService } from '@library/utils/services/user-agent.service';
import { Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { first, pluck } from 'rxjs/operators';
import { APP_NAME_CROWDIN_MAP } from 'src/assets/constant/app.constant';

@Directive()
export class ViewComponent {
  public activatedRoute: ActivatedRoute;
  public header: HeaderService;
  public callback: CallbackService;
  public userService: UserAgentService;
  public actions: Actions;
  public configFacade: ConfigFacade;
  public crowdins = APP_NAME_CROWDIN_MAP;

  constructor(public injector: Injector) {
    this.activatedRoute = injector.get(ActivatedRoute);
    this.header = injector.get(HeaderService);
    this.callback = injector.get(CallbackService);
    this.userService = injector.get(UserAgentService);
    this.actions = injector.get(Actions);
    this.configFacade = injector.get(ConfigFacade);
  }

  getRouteParam(name: string): Observable<unknown> {
    return this.activatedRoute.params.pipe(first(), pluck(name));
  }
}

import { moduleDefaults } from 'src/assets/constant/app.constant';
import { EnumModulesActionsVaillant, ModulesActionsVaillant } from './modules.actions';
import { ModuleVaillant } from './modules.interface';
import { initialModulesStateVaillant, ModulesStateVaillant } from './modules.state';

export function ModulesReducersVaillant(state = initialModulesStateVaillant, action: ModulesActionsVaillant): ModulesStateVaillant {
  switch (action.type) {
    /**
     * The heating curve is first initialised with a default value defined in "moduleDefaults.heatingCurveDefault"
     * When the default value is used we set the isDefaultSlope to true
     */
    case EnumModulesActionsVaillant.SetHeatingCurveSuccess: {
      const all = state.all.map((device: ModuleVaillant) => {
        if (device.id === action.payload.device_id) {
          device = {
            ...device,
            isDefaultSlope: moduleDefaults.heatingCurveDefault === action.payload.slope,
            heating_curve_slope: action.payload.slope ? action.payload.slope : moduleDefaults.heatingCurveDefault,
          };
        }
        return device;
      });

      return {
        ...state,
        all,
      };
    }
    /**
     * Reseting the heating curve means setting it to the value of "moduleDefaults.heatingCurveDefault"
     * and "isDefaultSlope" to true
     */
    case EnumModulesActionsVaillant.ResetHeatingCurveSuccess: {
      const all = state.all.map((device: ModuleVaillant) => {
        if (device.id === action.payload.device_id) {
          device = {
            ...device,
            isDefaultSlope: true,
            heating_curve_slope: moduleDefaults.heatingCurveDefault,
          };
        }
        return device;
      });

      return {
        ...state,
        all,
      };
    }

    case EnumModulesActionsVaillant.ChangeContactServiceSuccess: {
      const all = state.all.map((device: ModuleVaillant) => {
        if (device.id === action.payload.device_id) {
          // If reset, remove all current values from device in store
          // Allows selectors to pull default information on view
          if (action.payload.reset) {
            device = {
              ...device,
              contact_service_email: null,
              contact_service_name: null,
              contact_service_phone: null,
            };
          } else {
            device = {
              ...device,
              contact_service_phone: action.payload.phone ? action.payload.phone : device.contact_service_phone,
              contact_service_email: action.payload.email ? action.payload.email : device.contact_service_email,
              contact_service_name: action.payload.name ? action.payload.name : device.contact_service_name,
            };
          }
        }
        return device;
      });
      return {
        ...state,
        all,
      };
    }

    case EnumModulesActionsVaillant.SetDomesticHotWaterTemperatureSuccess: {
      const modules = action.payload.home?.modules;
      const all = state.all.map(module => {
        if (modules && modules.length > 0 && modules[0].id === module.id) {
          module = {
            ...module,
            config: {
              ...module.config,
              dhw_setpoint_temperature: modules[0].dhw_setpoint_temperature,
            },
          };
        }
        return module;
      });

      return {
        ...state,
        all,
      };
    }

    case EnumModulesActionsVaillant.SetHeatingAlgoSuccess: {
      const all = state.all.map(module => {
        if (module.id === action.payload.device_id) {
          module.therm_heating_algorithm = action.payload.algo_type;
          if (action.payload.algo_params) {
            module.simple_heating_algo_deadband = action.payload.algo_params.high_deadband;
          }
          module = { ...module };
        }
        return module;
      });

      return {
        ...state,
        all,
      };
    }

    case EnumModulesActionsVaillant.SetPeriodSuccess: {
      const device = state.all.find(dev => dev.id === action.payload.device_id);
      device.pid_algo_period = action.payload.period;
      const all = state.all.map((module) => {
        if (module.id === action.payload.device_id) {
          module = { ...module };
          module.pid_algo_period = action.payload.period;
        }
        return module;
      });
      return {
        ...state,
        all,
      };
    }

    case EnumModulesActionsVaillant.SetDHWControl: {
      const updateModule = action.payload.home.modules[0];
      const all = state.all.map((module) => {
        if (module.id === updateModule.id) {
          module = { ...module };
          module.dhw_control = updateModule.dhw_control;
        }
        return module;
      });
      return {
        ...state,
        all,
      };
    }

    default: return state;
  }
}

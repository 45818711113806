/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ConfigFacade } from '../../store/config/config.facade';
import { ConfigState } from '../../store/config/config.state';
import { Const } from '../constants/Const.constant';
import { CallbackService } from './callback.service';
import { UserAgentService } from './user-agent.service';

@Injectable({
  providedIn: 'root',
})
export class NgrxHttpClient {
  protected accessToken: string;
  protected badTokenCallback = null;
  config: ConfigState;
  private readonly errors = new Subject<{ code: number } | boolean>();
  public errors$ = this.errors.asObservable();
  protected trackedErrors = [
    400,
    404,
    405,
    500,
    501,
    0,
  ];

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService,
    protected callBack: CallbackService,
    protected configFacade: ConfigFacade,
    protected agent: UserAgentService) {
  }

  post(endpoint: string, params = {}, apiUrl?, options?: any, header?: Record<string, string>): Observable<any> {
    return this.configFacade.config$.pipe(
      take(1),
      switchMap((config) => {
        const httpOptions = {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.cookie.get(`${config.cookiePrefix}access_token`)}`,

            ...header,
          }),
          ...options,
        };

        return this.http.post(
          (apiUrl || config.apiUrl) + endpoint.toLowerCase(),
          params,
          httpOptions,
        ).pipe(
          catchError((error) => {
            this.handleError(error);
            throw error;
          }),
        );
      }),
    );
  }

  postSync(endpoint: string, params = {}, options?: any, header?: Record<string, string>): Observable<any> {
    return this.configFacade.config$.pipe(
      take(1),
      switchMap((config) => {
        return this.post(endpoint, params, config.syncApiUrl, options, header);
      },
      ));
  }

  get<T>(endpoint: string, params: any = {}, apiUrl?: string): Observable<T> {
    return this.configFacade.config$.pipe(
      take(1),
      switchMap((config) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${this.cookie.get(`${config.cookiePrefix}access_token`)}`,

        });

        return this.http.get<{ body: T }>(
          (apiUrl || config.apiUrl) + endpoint.toLowerCase(),
          {
            params,
            headers,
          },
        ).pipe(
          map(response => response.body),
          catchError((error) => {
            this.handleError(error);
            throw error;
          }),
        );
      }),
    );
  }

  getSync(endpoint: string, params = {}): Observable<any> {
    return this.configFacade.config$.pipe(
      take(1),
      switchMap((config) => {
        return this.get(endpoint, params, config.syncApiUrl);
      },
      ));
  }

  patch(endpoint: string, params = {}, apiUrl?: string): Observable<any> {
    return this.configFacade.config$.pipe(
      take(1),
      switchMap((config) => {
        const httpOptions = {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.cookie.get(`${config.cookiePrefix}access_token`)}`,

          }),
        };
        return this.http.patch<{ body: object }>(
          (apiUrl || config.apiUrl) + endpoint.toLowerCase(),
          params,
          httpOptions,
        ).pipe(
          map(response => response.body),
          catchError((error) => {
            this.handleError(error);
            throw error;
          }),
        );
      },
      ));
  }

  patchSync(endpoint: string, params = {}): Observable<any> {
    return this.configFacade.config$.pipe(
      take(1),
      switchMap((config) => {
        return this.patch(endpoint, params, config.syncApiUrl);
      },
      ));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  delete(endpoint: string, params = {}, apiUrl?: string): Observable<any> {
    return this.configFacade.config$.pipe(
      take(1),
      switchMap((config) => {
        const httpOptions = {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.cookie.get(`${config.cookiePrefix}access_token`)}`,

          }),
        };
        return this.http.delete<{ body: object }>(
          (apiUrl || config.apiUrl) + endpoint.toLowerCase(),
          httpOptions,
        ).pipe(
          map(response => response.body),
          catchError((error) => {
            this.handleError(error);
            throw error;
          }),
        );
      },
      ));
  }

  deleteSync(endpoint: string, params = {}): Observable<any> {
    return this.configFacade.config$.pipe(
      take(1),
      switchMap((config) => {
        return this.delete(endpoint, params, config.syncApiUrl);
      },
      ));
  }

  vpnCall(method: string, url: string, params = {}, preventGlobalError: boolean = false): Observable<any> {
    if (typeof url === 'undefined' || url.includes('undefined')) {
      this.handleError(new Error());
    }

    return this.http.get<{ body: object }>(
      `${url}/command/${method}`,
      { params },
    ).pipe(
      map((response: any) => {
        return response.body || response.conf || response;
      }),
      catchError((error) => {
        this.handleError(error, preventGlobalError);
        throw error;
      }),
    );
  }

  authorizeUri(params = {}): void {
    this.configFacade.config$.pipe(
      take(1),
      tap((config) => {
        // Add access token
        params['access_token'] = this.cookie.get(`${config.cookiePrefix}access_token`);

        let httpParams = new HttpParams();
        Object.keys(params).map((key) => {
          httpParams = httpParams.set(key, params[key]);
        });

        const url = `${config.oauth2ApiUrl}authorize?${httpParams.toString()}`;
        window.open(url, '_self');
      },
      ));
  }

  getPublic(endpoint: string, apiUrl: string, params: any = {}): Observable<any> {
    // get data without access token

    return this.http.get(
      apiUrl + endpoint,
      {
        params,
      },
    ).pipe(
      map(response => response),
      catchError((error) => {
        this.handleError(error);
        throw error;
      }),
    );
  }

  handleError(err, preventGlobalError = false): void {
    try {
      if (err.error.error !== undefined && err.error.error.code !== undefined) {
        switch (err.error.error.code) {
          case Const.NARestErrorCode.ACCESS_TOKEN_MISSING:
          case Const.NARestErrorCode.INVALID_ACCESS_TOKEN:
          case Const.NARestErrorCode.ACCESS_TOKEN_EXPIRED:
            this.handleTokenError();
            break;
          default:
        }
      }

      if (this.trackedErrors.includes(err.status) && !preventGlobalError) {
        this.errors.next({ code: err.status });
      }
    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.log('ApiModuleNg2 Unknown error', err);
      throw new Error('Unknown error');
    }

    console.error('ApiModuleNg2 error', err);
    throw err;
  }

  handleTokenError(): void {
    this.callBack.call('tokenExpired', undefined);
    if (!this.agent.isNativeApp()) {
      this.configFacade.config$.pipe(
        tap(config => {
          window.location.href = `${config.authUrl}/checklogin?next_url=${encodeURIComponent(window.location.href)}`;
        }),
      ).subscribe();
    }
  }

  dismissError(): void {
    this.errors.next(false);
  }
}

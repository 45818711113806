import { Injectable } from '@angular/core';
import { EnumPageLoaderActions, HidePageLoader, ShowPageLoader } from '@library/store/page-loader/page-loader.actions';
import { CallbackService } from '@library/utils/services/callback.service';
import { Actions, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '../app.state';

@Injectable({ providedIn: 'root' })
export class PageLoaderFacade {
  constructor(
    public store$: Store<AppState>,
    public callback: CallbackService,
    public actions$: Actions,

  ) {}

  pageLoading(): Observable<boolean> {
    return this.store$.pipe(select('isPageLoading'));
  }

  /**
   * Handles the page loader: hides the web page loader, then sends the onPageLoaded callback
   * @param onCompleteAction Optional: if no parameters, hides the page loader, otherwise hides the page loader when the action is fired
   */
  handlePageLoader(onCompleteAction?: string):void {
    this.store$.dispatch(new ShowPageLoader());

    if (!onCompleteAction) {
      this.hidePageLoader().subscribe(() => {
        this.callback.call(this.callback.list.onPageLoaded);
      });
    } else {
      this.actions$
        .pipe(
          ofType(onCompleteAction),
          first(),
          switchMap(() => this.hidePageLoader()),
          tap(() => {
            this.callback.call(this.callback.list.onPageLoaded);
          }),
        )
        .subscribe();
    }
  }

  /**
   * Hides the page loader
   * @returns Observable on action of type HidePageLoaderSuccess when it is fired
   */
  hidePageLoader(): Observable<Action> {
    this.store$.dispatch(new HidePageLoader());

    return this.actions$.pipe(
      ofType(EnumPageLoaderActions.HidePageLoaderSuccess),
      take(1),
    );
  }
}

import './assets/confs/config.js';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Enables CSS Variable polyfill for Theme var
import cssVars from 'css-vars-ponyfill';

cssVars({
  preserveVars: true,
  preserveStatic: true,
  watch: true,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { Injectable } from '@angular/core';
import { GetUser } from '@library/store/user/user.action';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class InitFacade {
  constructor(private readonly store: Store<SettingsState>) {}

  start():void {
    this.store.dispatch(GetUser());
  }
}

import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { SchedulesResolver } from '@library/store/schedules/schedules.resolver';
import { HomesResolver } from 'app/stores/homes/homes.resolver';
import { DeviceGuard } from './views/device-view/device.guard';
import { FeedbackViewComponent } from './views/feedback-view/feedback-view.component';
import { HomeViewComponent } from './views/home-view/home-view.component';
import { AdditionalQuestionsGuard } from './views/schedule-quiz-view/additional-questions.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'test',
        loadChildren: () =>
          import('./views/test-view/test-view.module').then(
            (m) => m.TestViewModule,
          ),
      },
      {
        path: 'home/:home_id',
        resolve: { HomesResolver },
        children: [
          {
            path: '',
            /**
             * Main and only entry point to the settings
             * Contains device-list-view-component
             */
            component: HomeViewComponent,
            data: { animation: 'RouteState1' },
            resolve: { HomesResolver },
          },
          /**
           * Thermostat view (thermostat + gateway information)
           */
          {
            path: 'device/:device_id',
            loadChildren: () =>
              import('./views/device-view/device-view.module').then(
                (m) => m.HomeViewModule,
              ),
            canLoad: [DeviceGuard],
            canActivate: [DeviceGuard],
            resolve: { HomesResolver },
          },
          {
            path: 'users/:device_id',
            loadChildren: () =>
              import('./views/users-view/users-view.module').then(
                (m) => m.DeviceUsersViewModule,
              ),
            canLoad: [DeviceGuard],
            canActivate: [DeviceGuard],
            resolve: { HomesResolver },
          },
          {
            path: 'heatingsystem/:device_id',
            loadChildren: () =>
              import(
                './views/advanced-settings-view/advanced-settings-view.module'
              ).then((m) => m.AdvancedSettingsViewModule),
            canLoad: [DeviceGuard],
            canActivate: [DeviceGuard],
            resolve: { HomesResolver },
          },
          {
            path: 'feedback',
            loadChildren: () =>
              import(
                './views/feedback-view/feedback-view.module'
              ).then((m) => m.FeedbackViewModule),
            component: FeedbackViewComponent,
            data: { animation: 'RouteState1' },
            resolve: { HomesResolver },
          },
        ],
      },
      {
        path: 'schedule-quiz/:home_id/:name',
        loadChildren: () =>
          import(
            './views/schedule-quiz-view/schedule-quiz-routing.module'
          ).then((m) => m.ScheduleQuizRoutingModule),
        data: { animation: 'Forward' },
        resolve: {
          schedules: SchedulesResolver,
        },
      },

    ],
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./views/faq-view/faq-view.module').then((m) => m.FaqViewModule),
  },
  {
    path: 'contact/:home_id',
    loadChildren: () =>
      import('./views/contact-service-view/contact-service-view.module').then(
        (m) => m.ContactServiceViewModule,
      ),
    resolve: { HomesResolver },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: NoPreloading,
    }),
  ],
  providers: [DeviceGuard, AdditionalQuestionsGuard],
  exports: [RouterModule],
})
export class AppRoutingModule { }

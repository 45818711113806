import { Injectable } from '@angular/core';
import { EnumHomesActions, SetTemperatureControlMode } from '@library/store/homes/homes.action';
import { getCurrentHome } from '@library/store/homes/homes.selector';
import { getCurrentHomeModules } from '@library/store/modules/modules.selector';
import { getcurrentHomeRooms } from '@library/store/rooms/rooms.selector';
import { EnumSchedulesActions, ModifySchedule } from '@library/store/schedules/schedules.action';
import { Schedule, TimetableSlot } from '@library/store/schedules/schedules.interface';
import { getQuizzData } from '@library/store/schedules/schedules.selector';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { Actions, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ModulesFacadeVaillant } from '../modules/modules.facade';
import * as SchedulesActionsVaillant from './schedules.action';
import { ScheduleModelVaillant } from './schedules.model';

@Injectable({ providedIn: 'root' })
export class SchedulesFacadeVaillant {
  constructor(
    private readonly store: Store<SettingsState>,
    private readonly actions$: Actions,
    private readonly modulesFacadeVaillant: ModulesFacadeVaillant,
  ) { }

  quizzData$ = this.store.pipe(select(getQuizzData));
  currentHome$ = this.store.pipe(select(getCurrentHome));
  currentHomeRooms$ = this.store.pipe(select(getcurrentHomeRooms));
  currentHomeModules$ = this.store.pipe(select(getCurrentHomeModules));

  /**
   * Schedule newly created
   */
  newSchedule: ScheduleModelVaillant;
  params: object;

  /**
   * Creates the timetable according to the data entered in the quizz,
   * then creates and activates the new schedule
   * @param scheduleName Name of the schedule
   * @returns Observable on action of type CreateNewScheduleSuccess or CreateNewScheduleFailure when it is fired
   */
  createNewScheduleVaillant(scheduleName: string): Observable<Action> {
    return combineLatest([
      this.quizzData$,
      this.currentHome$,
      this.currentHomeRooms$,
      this.currentHomeModules$,
    ]).pipe(
      map((
        [quizzData, currentHome, currentHomeRooms, currentHomeModules],
      ) => ({ quizzData, currentHome, currentHomeRooms, currentHomeModules })),
      tap(data => {
        this.newSchedule = new ScheduleModelVaillant(data.quizzData, data.currentHome, data.currentHomeRooms, data.currentHomeModules, 'therm');

        const scheduleParams = this.newSchedule.saveTimeTable();

        // Failsafe block for decoding in case upstream errors occur
        try {
          scheduleName = decodeURIComponent(scheduleName);
        } catch {
          // Do nothing to change if schedule name cannot be decoded
        }

        const params = {
          home_id: data.currentHome.id,
          name: scheduleName,
          timetable: JSON.stringify(scheduleParams.timetable),
          zones: JSON.stringify(scheduleParams.zones),
          hg_temp: 7,
          away_temp: 16,
          schedule_type: 'therm',
        } as unknown as Schedule;

        const defaultSchedule = data.currentHome.schedules.find(schedule => schedule.default && schedule.type === data.quizzData.type);

        // Replace the default schedule if it exists, else create a new schedule
        if (typeof defaultSchedule !== 'undefined') {
          params.schedule_id = defaultSchedule.id;
          this.store.dispatch(new ModifySchedule(params));
          if (data.quizzData.hasDefaultSchedules) {
            // Change control mode
            this.store.dispatch(new SetTemperatureControlMode({
              home: {
                id: data.currentHome.id,
                temperature_control_mode: 'heating',
              },
            }));
          }
        } else {
          this.store.dispatch(new SchedulesActionsVaillant.CreateNewScheduleVaillant(params));
        }
      }),
      /**
       * Wait for a failure action (CreateNewScheduleFailure or SelectNewScheduleFailure)
       * or the SelectNewScheduleSuccess action before emitting
       */
      switchMap(() => this.actions$),
      ofType(
        EnumSchedulesActions.CreateNewScheduleFailure,
        EnumSchedulesActions.CreateNewScheduleSuccess,
        EnumSchedulesActions.ModifyScheduleFailure,
        EnumSchedulesActions.ModifyScheduleSuccess,
        EnumSchedulesActions.SelectNewScheduleSuccess,
        EnumSchedulesActions.SelectNewScheduleFailure,
        EnumHomesActions.SetTemperatureControlModeSuccess,
        EnumHomesActions.SetTemperatureControlModeFailure,
      ),
      take(1),
    );
  }

  createEventZones(timetable: TimetableSlot[], scheduleName: string, thermScheduleId: string): Observable<object> {
    return combineLatest([
      this.quizzData$,
      this.currentHome$,
      this.currentHomeRooms$,
      this.currentHomeModules$,
      this.modulesFacadeVaillant.currentHomeGateway$,
    ]).pipe(
      map((
        [quizzData, currentHome, currentHomeRooms, currentHomeModules, gateway],
      ) => ({ quizzData, currentHome, currentHomeRooms, currentHomeModules, gateway })),
      tap(data => {
        // We instanciate the ScheduleModelVaillant class with scheduleType=event to create the event zones
        this.newSchedule = new ScheduleModelVaillant(data.quizzData, data.currentHome, data.currentHomeRooms, data.currentHomeModules, 'event');

        // Generate the event zones using the gateway id and default dhw values for all the zones
        const scheduleParams = this.newSchedule.saveTimeTableVaillant(data.gateway.id);

        this.params = {
          home_id: data.currentHome.id,
          name: scheduleName,
          zones: JSON.stringify(scheduleParams.eventZones),
          timetable,
          schedule_type: 'event',
          schedule_hwb_associated_vaillant: thermScheduleId,
        };
      }),
      /**
       * We return the params to send the CreateNewSchedule action from the effect
       */
    ).pipe(
      map(() => this.params),
    );
  }
}

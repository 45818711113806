import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  first,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { HomesFacadeVaillant } from './homes.facade';

@Injectable()
export class HomesResolver {
  constructor(private readonly facade: HomesFacadeVaillant) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean | Action<string>> {
    return of(route.params.home_id as string).pipe(
      withLatestFrom(this.facade.homes$),
      switchMap(([homeId, homes]) => {
        if (!homes.some((h) => h.id === homeId)) {
          return this.facade.loadHome(homeId, { home_id: homeId, detailed_config: true });
        } else {
          this.facade.selectHome(homeId);
          return of(true);
        }
      }),
      first(),
    );
  }
}

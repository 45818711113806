import { Injectable } from '@angular/core';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { EnumHomesActions, SetTemperatureControlMode } from '../homes/homes.action';
import * as HomesSelectors from '../homes/homes.selector';
import { getCurrentHomeModules } from '../modules/modules.selector';
import * as RoomsSelectors from '../rooms/rooms.selector';
import { AutoCapabableDevices } from './schedule.consts';
import * as SchedulesActions from './schedules.action';
import { QuizzData, Schedule } from './schedules.interface';
import { ScheduleModel } from './schedules.model';
import * as SchedulesSelectors from './schedules.selector';

@Injectable({ providedIn: 'root' })
export class SchedulesFacade {
  quizzData$ = this.store.pipe(select(SchedulesSelectors.getQuizzData));
  currentHome$ = this.store.pipe(select(HomesSelectors.getCurrentHome));
  currentHomeRooms$ = this.store.pipe(select(RoomsSelectors.getcurrentHomeRooms));
  currentHomeModules$ = this.store.pipe(select(getCurrentHomeModules));
  currentHomeSchedules = this.store.pipe(select(SchedulesSelectors.currentHomeSchedules));
  currentSelectedThermSchedule$ = this.store.pipe(select(SchedulesSelectors.currentSelectedThermSchedule));
  currentSelectedCoolingSchedule$ = this.store.pipe(select(SchedulesSelectors.currentSelectedCoolingSchedule));
  currentSelectedAutoSchedule$ = this.store.pipe(select(SchedulesSelectors.currentSelectedAutoSchedule));
  thermSchedules$ = this.store.pipe(select(SchedulesSelectors.thermSchedules));

  /**
   * Schedule newly created
   */
  newSchedule: ScheduleModel;

  constructor(
    protected store: Store<SettingsState>,
    protected actions$: Actions,
  ) { }

  /**
   * Loads schedules of the current home and puts them in the schedules store
   * @param params Parameters of the request
   * @returns Observable on action of type GetSchedulesSuccess when it is fired
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadSchedules(params: any): Observable<Action> {
    this.store.dispatch(new SchedulesActions.GetSchedules(params));

    return this.actions$.pipe(
      ofType(SchedulesActions.EnumSchedulesActions.GetSchedulesSuccess),
      take(1),
    );
  }

  /**
   * Updates the quizz data of the store
   * @param params Data to update
   */
  saveQuizzData(params: Partial<QuizzData>): void {
    this.store.dispatch(new SchedulesActions.SetQuizzData(params));
  }

  /**
   * Creates the timetable according to the data entered in the quizz,
   * then creates and activates the new schedule
   * @param scheduleName Name of the schedule
   * @returns Observable on action of type CreateNewScheduleSuccess or CreateNewScheduleFailure when it is fired
   */
  createNewSchedule(scheduleName: string): Observable<Action> {
    return combineLatest([
      this.quizzData$,
      this.currentHome$,
      this.currentHomeRooms$,
      this.currentHomeModules$,
    ]).pipe(
      map((
        [quizzData, currentHome, currentHomeRooms, currentHomeModules],
      ) => ({ quizzData, currentHome, currentHomeRooms, currentHomeModules })),
      tap(data => {
        this.newSchedule = new ScheduleModel(data.quizzData, data.currentHome, data.currentHomeRooms, data.currentHomeModules);

        const scheduleParams = this.newSchedule.saveTimeTable();

        const hasAutoCapableDevice = data.currentHomeModules.some((mod) => AutoCapabableDevices.includes(mod.type));

        const params = {
          home_id: data.currentHome.id,
          name: scheduleName,
          timetable: JSON.stringify(scheduleParams.timetable),
          zones: JSON.stringify(scheduleParams.zones),
          hg_temp: 7,
          away_temp: 12,
          schedule_type: 'therm',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any;

        if (hasAutoCapableDevice) {
          params['away_temperature_mode'] = 'off';
        }

        // Transform data for cooling schedules
        if (data.quizzData.type === 'cooling') {
          params['cooling_away_temp'] = 30;
          params['schedule_type'] = 'cooling';
          delete params.hg_temp;
          delete params.away_temp;
        }

        // Need to also set away temperature mode off if the schedule is auto
        // Also need to set hg_temp  to different value
        if (data.quizzData.type === 'auto') {
          params['auto_away_temp'] = 30;
          params['schedule_type'] = 'auto';
          params['away_temperature_mode'] = 'off';
          params['hg_temp'] = 3;
          delete params.away_temp;
        }

        const defaultSchedule = data.currentHome.schedules?.find(schedule => schedule.default && schedule.type === data.quizzData.type);

        // Replace the default schedule if it exists, else create a new schedule
        if (typeof defaultSchedule !== 'undefined') {
          params.schedule_id = defaultSchedule.id;
          this.store.dispatch(new SchedulesActions.ModifySchedule(params));
          if (data.quizzData.hasDefaultSchedules) {
            // Change control mode
            this.store.dispatch(new SetTemperatureControlMode({
              home: {
                id: data.currentHome.id,
                temperature_control_mode: data.quizzData.type === 'therm' ? 'heating' : 'cooling',
              },
            }));
          }
        }
        else {
          this.store.dispatch(new SchedulesActions.CreateNewSchedule(params));
        }
      }),
      /**
       * Wait for a failure action (CreateNewScheduleFailure or SelectNewScheduleFailure)
       * or the SelectNewScheduleSuccess action before emitting
       */
      switchMap(() => this.actions$),
      ofType(
        SchedulesActions.EnumSchedulesActions.CreateNewScheduleFailure,
        SchedulesActions.EnumSchedulesActions.CreateNewScheduleSuccess,
        SchedulesActions.EnumSchedulesActions.ModifyScheduleFailure,
        SchedulesActions.EnumSchedulesActions.ModifyScheduleSuccess,
        SchedulesActions.EnumSchedulesActions.SelectNewScheduleSuccess,
        SchedulesActions.EnumSchedulesActions.SelectNewScheduleFailure,
        EnumHomesActions.SetTemperatureControlModeSuccess,
        EnumHomesActions.SetTemperatureControlModeFailure,
      ),
      take(1),
    );
  }

  selectNewSchedule(homeId: string, scheduleId: string) {
    this.store.dispatch(new SchedulesActions.SelectNewSchedule({ home_id: homeId, schedule_id: scheduleId }));
    return this.actions$.pipe(
      ofType(SchedulesActions.EnumSchedulesActions.SelectNewScheduleSuccess, SchedulesActions.EnumSchedulesActions.SelectNewScheduleFailure),
      take(1),
    );
  }

  createNewScheduleWithoutQuizz(schedule: Schedule): Observable<Action> {
    this.store.dispatch(new SchedulesActions.CreateNewSchedule(schedule));
    return this.actions$.pipe(
      ofType(SchedulesActions.EnumSchedulesActions.CreateNewScheduleSuccess, SchedulesActions.EnumSchedulesActions.CreateNewScheduleFailure),
      take(1),
    );
  }

  loadOnlySchedules(home) {
    // when we already have homes loaded, we only want to dispatch them in the store
    this.store.dispatch(new SchedulesActions.GetSchedulesSuccess(home));
  }

  modifySchedule(schedule: Schedule): Observable<Action> {
    this.store.dispatch(new SchedulesActions.ModifySchedule(schedule));
    return this.actions$.pipe(
      ofType(SchedulesActions.EnumSchedulesActions.ModifyScheduleSuccess, SchedulesActions.EnumSchedulesActions.ModifyScheduleFailure),
      take(1),
    );
  }

  deleteSchedule(home_id: string, schedule_id: string) {
    this.store.dispatch(new SchedulesActions.DeleteSchedule({ home_id, schedule_id }));
    return this.actions$.pipe(
      ofType(SchedulesActions.EnumSchedulesActions.DeleteScheduleSuccess, SchedulesActions.EnumSchedulesActions.DeleteScheduleFailure),
      take(1),
    );
  }
}

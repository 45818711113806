@if ((data$ | async); as data) {
  <lay-panel>
    <lay-panel-content>
      <app-device-list-view />
      <lay-panel-block>
        <lay-title>
          <app-text class="is-family-semiBold "
            [text]="'common-settings.__MY_HELP' | LANG" />
        </lay-title>
      </lay-panel-block>
      <lay-panel-block>
        <lay-link route="/faq">
          <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
            name="question_2-vaillant" />
          <app-text class="title" [text]="'common-settings.__FAQ' | LANG" />
        </lay-link>
      </lay-panel-block>
      <lay-panel-block>
        <lay-link (click)="getFeedback(data.homeId)" [resolve]="false">
          <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
            name="communication-vaillant" />
          <app-text class="title" [text]="'settings-all.__VAI_SETTINGS_MAIN_CONTACT_US' | LANG" />
        </lay-link>
      </lay-panel-block>
      <lay-panel-block>
        <lay-link (click)="getContactService(data.homeId)" [resolve]="false">
          <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
            name="contact-vaillant" />
          <app-text class="title" [text]="(crowdins[data.config.appName] +'.__SERVICE_CONTACT') | LANG" />
        </lay-link>
      </lay-panel-block>
      <lay-panel-block>
        <lay-title>
          <app-text class="is-family-semiBold "
            [text]="'settings-all.__VAI_SETTINGS_HEATING_GENERAL' | LANG" />
        </lay-title>
      </lay-panel-block>
      <lay-panel-block>
        <lay-link (click)="getMyAccountUrl(data.config)" [resolve]="false">
          <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
            name="account-vaillant" />
          <app-text class="title" [text]="'common-settings.__ME' | LANG" />
        </lay-link>
      </lay-panel-block>
      <lay-panel-block>
        <lay-link (click)="getImprint()">
          <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
            name="law-vaillant" />
          <app-text class="title" [text]="'settings-all.__VAI_SETTINGS_MAIN_IMPRINT' | LANG" />
        </lay-link>
      </lay-panel-block>
      <lay-panel-block>
        <lay-link (click)="logout(data.config)">
          <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
            name="exit-vaillant" />
          <app-text class="title" [text]="'common-settings.__MY_LOGOUT' | LANG" />
        </lay-link>
      </lay-panel-block>
    </lay-panel-content>
  </lay-panel>
}

import { Injectable } from '@angular/core';
import { ConfigFacade } from '@library/store/config/config.facade';
import { EnumHomesActions, HomesActions, UpdateHomeName } from '@library/store/homes/homes.action';
import { HomesFacade } from '@library/store/homes/homes.facade';
import { HomesNameUpdate } from '@library/store/homes/homes.interface';
import { getCurrentHome } from '@library/store/homes/homes.selector';
import { Share } from '@library/utils/interfaces/general-interfaces.interface';
import { LangPipe } from '@library/utils/pipes/lang.pipe';
import { Actions, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { APP_NAME_CROWDIN_MAP } from 'src/assets/constant/app.constant';
import { AppState } from '../app.state';
import * as AllHomesActionsVaillant from './homes.actions';
import { EnumHomesActionsVaillant, HomesActionsVaillant } from './homes.actions';
import { DHWStorageUpdatePayload, HomeVaillant } from './homes.interface';
import * as HomesSelectorsVaillant from './homes.selector';

@Injectable({ providedIn: 'root' })
export class HomesFacadeVaillant extends HomesFacade {
  currentHome$ = this.currentHome$ as Observable<HomeVaillant>;
  getCurrentHomeVaillant$ = this.store$.pipe(select(HomesSelectorsVaillant.getCurrentHomeVaillant));
  getSetpoinDefaultDuration$ = this.store$.pipe(select(HomesSelectorsVaillant.getSetpoinDefaultDuration));
  getAnticipation$ = this.store$.pipe(select(HomesSelectorsVaillant.getAnticipation));
  heatingType$ = this.store$.pipe(select(HomesSelectorsVaillant.getHeatingType));

  constructor(
    store$: Store<AppState>,
    actions$: Actions<HomesActions>,
    private readonly langPipe: LangPipe,
    private readonly configFacade: ConfigFacade,
  ) {
    super(store$, actions$);
  }

  /**
   * Loads homes data, then modules status, then modules config
   * @param id ID of current home
   * @param params Parameters of the request
   * @returns Observable on action of type GetHomesConfigSuccess when it is fired
   */
  loadAll(id: string, params: object): Observable<Action> {
    return this.loadHome(id, params).pipe(
      switchMap(() => this.loadHomeStatus({ home_id: id })),
      switchMap(() => this.loadHomeConfig({ home_id: id })),
    );
  }

  getHomeInvitation(): Observable<Share> {
    return this.store$.pipe(
      select(getCurrentHome),
      take(1),
      switchMap((home) => {
        return this.getAdminAccessCode(home.id).pipe(
          switchMap(() => {
            return combineLatest([
              this.configFacade.config$,
              this.adminAccessCode$,
            ]).pipe(
              switchMap(([config, adminAccessCode]) => {
                const url = `${config.invitationUrl}${adminAccessCode}/${home.id}`;
                const crowdin = APP_NAME_CROWDIN_MAP[config.appName]; // vaillant or migo
                const text = this.langPipe.transform(
                  `${crowdin}.__ADD_ADMIN_BODY`,
                  [home.name, ''],
                )
                  .replace(/<br\/>/g, '\n')
                  .replace(/\\n/g, '\n')
                  .split('%5Cn').join('%0D%0A');

                const data: Share = {
                  title: this.langPipe.transform(
                    'common-app.__B2B_ADD_ADMIN_SUBJECT',
                  ),
                  url,
                  text,
                };
                return of(data);
              }),
            );
          }),
        );
      }),
    );
  }

  /**
   * Updates the name of a home
   * @param homeNameUpdate Parameters of the request: home_id, name
   */
  updateHomeName(homeNameUpdate: HomesNameUpdate):Observable<HomesActions> {
    this.store$.dispatch(
      new UpdateHomeName(homeNameUpdate),
    );
    return this.actions$.pipe(
      ofType<HomesActions>(
        EnumHomesActions.UpdateHomeNameSuccess,
        EnumHomesActions.UpdateHomeNameFailure,
      ),
      take(1),
    );
  }

  setSetpoinDefaultDuration(setpointUpdate: { home: Partial<HomeVaillant> }): Observable<AllHomesActionsVaillant.HomesActionsVaillant> {
    this.store$.dispatch(new AllHomesActionsVaillant.SetManualSetpointDefaultDuration(setpointUpdate));

    return this.actions$.pipe(
      ofType<HomesActionsVaillant>(
        EnumHomesActionsVaillant.SetManualSetpointDefaultDurationSuccess,
        EnumHomesActionsVaillant.SetManualSetpointDefaultDurationFailure,
      ),
      take(1),
    );
  }

  setAnticipationActivation(anticipationUpdate: { home: Partial<HomeVaillant> }): Observable<AllHomesActionsVaillant.HomesActionsVaillant> {
    this.store$.dispatch(new AllHomesActionsVaillant.SetAnticipationActivation(anticipationUpdate));

    return this.actions$.pipe(
      ofType<HomesActionsVaillant>(
        EnumHomesActionsVaillant.SetAnticipationActivationSuccess,
        EnumHomesActionsVaillant.SetAnticipationActivationFailure,
      ),
      take(1),
    );
  }

  setDHWStorage(storageUpdate: DHWStorageUpdatePayload): Observable<AllHomesActionsVaillant.HomesActionsVaillant> {
    this.store$.dispatch(new AllHomesActionsVaillant.SetDHWStorage(storageUpdate));

    return this.actions$.pipe(
      ofType<HomesActionsVaillant>(
        EnumHomesActionsVaillant.SetDHWStorageSuccess,
        EnumHomesActionsVaillant.SetDHWStorageFailure,
      ),
      take(1),
    );
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, UrlSegment } from '@angular/router';
import { ModulesFacadeVaillant } from 'app/stores/modules/modules.facade';
import { Observable } from 'rxjs';
import { first, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class DeviceGuard {
  constructor(
    private readonly modulesFacade: ModulesFacadeVaillant,
  ) { }

  checkDeviceExistence(deviceId: string): Observable<boolean> {
    return this.modulesFacade.checkExistance$.pipe(
      first(moduleExists => moduleExists === true),
      switchMap(() => this.modulesFacade.currentHomeModules$.pipe(
        take(1),
        map(modules => modules.map(m => m.id).includes(deviceId)),
      )),
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    const deviceId = segments[1].path;
    return this.checkDeviceExistence(deviceId).pipe(
      tap(res => {
        if (!res) { throw new Error('The device does not exist'); }
        this.modulesFacade.selectModule(deviceId);
      }),
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const deviceId = route.paramMap.get('device_id');
    return this.checkDeviceExistence(deviceId).pipe(
      tap(res => {
        if (res) {
          this.modulesFacade.selectModule(deviceId);
        } else {
          throw new Error('The device does not exist');
        }
      }),
    );
  }
}

import { Module } from '@library/store/modules/modules.interface';
import { ModulesState } from '@library/store/modules/modules.state';
import { Schedule } from '@library/store/schedules/schedules.interface';
import { Enums } from '@library/utils/constants/Enums.constant';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { createSelector } from '@ngrx/store';
import { ContractTypes } from '../contracts/contracts.interface';
import { getCurrentHomeModules } from '../modules/modules.selector';
import { getcurrentHomeRooms } from '../rooms/rooms.selector';
import { countryPrice } from './countriesPriceCorrespondance.const';
import { AutoTempModes, Capability, CoolingModes, Home, TemperatureControlModes, ThermModes } from './homes.interface';

const homesState = (state: SettingsState) => state.homes;
const modulesState = (state: SettingsState) => state.modules;

export const getHomes = createSelector(
  homesState,
  state => {
    return state.homes;
  },
);

export const getCurrentHomeId = createSelector(
  homesState,
  state => {
    return state.currentHomeId;
  },
);

export const isLoadingHomes = createSelector(
  homesState,
  state => {
    return state.loading;
  },
);

export const getErrorModalDisplay = createSelector(
  homesState,
  state => {
    return state.errorModalDisplay;
  },
);

export const getCurrentHome = createSelector(
  getHomes,
  getCurrentHomeId,
  (homes: Home[], id: string) => {
    return homes.find(home => home.id === id);
  },
);

export const getHomeUsers = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.users ?? null;
  },
);

export const getAdminAccessCode = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.admin_access_code ?? null;
  },
);

export const getHomeCoordinates = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.place?.coordinates ?? null;
  },
);

export const getHomeAltitude = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.place?.altitude ?? null;
  },
);

export const getHomeTimezone = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.place?.timezone ?? null;
  },
);

export const isHomeTemperatureControlled = createSelector(
  getCurrentHome,
  modulesState,
  (home: Home, moduleState: ModulesState) => {
    const modulesHome = moduleState.all.filter(mod => mod.homeId === home.id);
    let temperatureControlled = false;

    modulesHome.forEach(module => {
      if (module.canControlTemperature) {
        temperatureControlled = true;
      }
    });

    return temperatureControlled;
  },
);

export const getHomeName = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home.name;
  },
);

export const getHomeAddress = createSelector(
  getHomes,
  (homes: Home[]) => {
    return homes[0].place.address;
  },
);

export const getCurrentHomeCountry = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.place?.country ?? null;
  },
);

export const isFrance = createSelector(
  getCurrentHomeCountry,
  (country: string) => {
    return country === 'FR';
  },
);

export const getCurrentHomeCapabilities = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.capabilities ?? [];
  },
);

export const isSupportedCountry = createSelector(
  getCurrentHomeCountry,
  (country: string) => {
    // supported countries are the ones included in countryPrice array and that do not take part of forbidden ones
    return (
      typeof countryPrice.find((c) => c.code === country) !== 'undefined'
    );
  },
);

export const electricitySchedule = createSelector(
  getCurrentHome,
  (home: Home) => {
    // Return selected electricity schedule
    return home?.schedules?.find(schedule => {
      return schedule.type === 'electricity' && schedule.selected;
    }) ?? null;
  },
);

export const currencySymbol = createSelector(
  getCurrentHomeCountry,
  isSupportedCountry,
  (country: string, isSupported: boolean) => {
    if (isSupported) {
      const countryObj = countryPrice.find((c) => c.code === country);
      if (countryObj) {
        return countryObj.currencySymbol;
      }
    }
    return '€';
  },
);

export const getCurrentHomeSchedules = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.schedules ?? [];
  },
);

export const supportElectricitySchedule = createSelector(
  getCurrentHomeCapabilities,
  (capabilities: Capability[]) => {
    return capabilities?.some((capability) => {
      return capability.name === 'electricity_contract' && capability.available;
    }) ?? false;
  },
);

export const getCurrentHomeElectricitySchedules = createSelector(
  getCurrentHomeSchedules,
  (schedules: Schedule[]) => {
    return schedules.filter(s => s.type === ContractTypes.consumption);
  },
);

export const productionElectricitySchedule = createSelector(
  getCurrentHomeSchedules,
  (schedules: Schedule[]) => {
    return schedules.find(s => s.type === ContractTypes.production);
  },
);

export const hasDefaultElectricitySchedule = createSelector(
  getCurrentHomeElectricitySchedules,
  (schedules: Schedule[]) => {
    return schedules.some(schedule => schedule.default);
  },
);

export const defaultElectricityContractId = createSelector(
  getCurrentHomeElectricitySchedules,
  (schedules: Schedule[]) => {
    schedules.find(s => s.default)?.id ?? null;
  },
);

export const electricityPowerThreshold = createSelector(
  getCurrentHomeElectricitySchedules,
  (schedules: Schedule[]) => {
    return schedules[0]?.power_threshold;
  },
);

export const electricityPowerUnit = createSelector(
  getCurrentHomeElectricitySchedules,
  (schedules: Schedule[]) => {
    return schedules[0]?.contract_power_unit;
  },
);

export const hasElectricitySchedules = createSelector(
  getCurrentHomeElectricitySchedules,
  (schedules: Schedule[]) => {
    return schedules?.length > 0;
  },
);

export const getCurrentHomePersons = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.persons ?? [];
  },
);

export const currentStatusLoaded = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home.status_loaded;
  },
);

export const currentConfigLoaded = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home.config_loaded;
  },
);

export const currentHomeHasNDL = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules.findIndex(m => m.type === 'NDL') >= 0;
  },
);

export const hasBNSE = createSelector(
  getCurrentHomeModules,
  (modules) => modules.some(mod => mod.type === 'BNSE'),
);

export const hasNLE = createSelector(
  getCurrentHomeModules,
  (modules) =>
    modules.some(mod => mod.type === Enums.ModuleType.NLE),
);

export const hasNLG = createSelector(
  getCurrentHomeModules,
  (modules) =>
    modules.some(mod => mod.type === Enums.ModuleType.NLG),
);

export const getHomeType = createSelector(
  homesState,
  state => {
    const homes = state.homes;
    const home = homes.find(h => h.id === state.currentHomeId);

    if (!home || !home.home_type) {
      return 'unknown';
    }
    return home.home_type;
  },
);

export const heatingSchedules = createSelector(
  getCurrentHome,
  (home: Home) => home?.schedules?.filter(schedule => schedule.type === 'therm'),
);

export const coolingSchedules = createSelector(
  getCurrentHome,
  (home: Home) => home?.schedules?.filter(schedule => schedule.type === 'cooling'),
);

export const autoSchedules = createSelector(
  getCurrentHome,
  (home: Home) => home?.schedules?.filter(schedule => schedule.type === 'auto'),
);

export const temperatureControlMode = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home.temperature_control_mode;
  },
);

export const thermMode = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home.therm_mode as ThermModes;
  },
);

export const coolingMode = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.cooling_mode;
  },
);

export const autoTempMode = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.auto_temp_mode;
  },
);

export const thermEndDate = createSelector(
  getCurrentHome,
  (home: Home) => {
    if (home.therm_mode === 'hg' || home.therm_mode === 'away') {
      return home.therm_mode_endtime;
    }
    else {
      return null;
    }
  },
);

export const currentModeEndTime = createSelector(
  getCurrentHome,
  temperatureControlMode,
  thermMode,
  coolingMode,
  autoTempMode,
  (home, temperatureControlMode, thermMode, coolingMode, autoTempMode) => {
    if (temperatureControlMode === TemperatureControlModes.HEATING) {
      return thermMode !== ThermModes.SCHEDULE ? home.therm_mode_endtime : null;
    }
    else if (temperatureControlMode === TemperatureControlModes.COOLING) {
      return coolingMode !== CoolingModes.SCHEDULE ? home.cooling_mode_endtime : null;
    }
    else if (temperatureControlMode === TemperatureControlModes.AUTO) {
      return autoTempMode !== AutoTempModes.SCHEDULE ? home.auto_mode_endtime : null;
    }
  },
);

export const thermModeEndTime = createSelector(
  getCurrentHome,
  thermMode,
  (home: Home, thermoMode: ThermModes) => {
    return thermoMode !== ThermModes.SCHEDULE ? home.therm_mode_endtime : null;
  },
);

export const coolingModeEndTime = createSelector(
  getCurrentHome,
  coolingMode,
  (home: Home, coolingMode: CoolingModes) => {
    return coolingMode !== CoolingModes.SCHEDULE ? home.cooling_mode_endtime : null;
  },
);

export const autoModeEndTime = createSelector(
  getCurrentHome,
  autoTempMode,
  (home: Home, autoTempMode: AutoTempModes) => {
    return autoTempMode !== AutoTempModes.SCHEDULE ? home.auto_mode_endtime : null;
  },
);

export const supportsProduction = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules.some(module => module.measured_elec_type === 'production');
  },
);

export const supportsOverproduction = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    const hasTotalConsumptionMeasurer = modules.some(module => {
      return module.type === 'NLPS' || (module.type === 'NLPC' && module.measured_elec_type === 'consumption' && module.measures_scope === 'total');
    });
    const hasTotalProductionMeasurer = modules.some(module => {
      return module.type === 'NLPC' && module.measured_elec_type === 'production' && module.measures_scope === 'total';
    });
    return hasTotalConsumptionMeasurer && hasTotalProductionMeasurer;
  },
);

export const selectedHeatingSchedule = createSelector(
  heatingSchedules,
  (schedules) => schedules.find(schedule => schedule.selected),
);

export const hgTemperature = createSelector(
  selectedHeatingSchedule,
  (schedule: Schedule) => {
    return schedule?.hg_temp;
  },
);

export const homeThermostats = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules.filter(mod => mod.type === 'NATherm1');
  },
);

export const hasThermostat = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules.some(mod => ['NATherm1', 'OTH', 'BNS', 'NTE'].includes(mod.type));
  },
);

export const supportsCooling = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules.some(module => module.config?.specifications?.temperature_control_mode?.values.length > 1);
  },
);

export const homeThermRelays = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules.filter(mod => ['NAPlug'].includes(mod.type)).map(mod => mod.id);
  },
);

export const hasRoomControlledByUnkownThermRelay = createSelector(
  getcurrentHomeRooms,
  homeThermRelays,
  (rooms, homeThermRelays) => {
    const thermRelays = [];
    for (const i in rooms) {
      if (rooms[i].therm_relay && thermRelays.map(relay => relay.id).indexOf(rooms[i].therm_relay) === -1) {
        thermRelays.push(rooms[i].therm_relay);
      }
    }
    for (const j of thermRelays) {
      if (!homeThermRelays.includes(j)) {
        return true;
      }
    }

    return false;
  },
);
